import { useState, useRef, useEffect } from 'react';
import { Alert, Button, Input, Textarea } from '@chakra-ui/react';

import Card from 'components/card';
import PassInput from "./components/PassInput";

import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { useDisclosure } from '@chakra-ui/hooks';
import InputField from './components/InputField';
import { MdBarChart, MdDashboard, MdMessage, MdSearch, MdAdd, MdInsertEmoticon } from 'react-icons/md';
import api from 'api/api';
import TextField from './components/TextField';
import Toast from 'components/toast';
import { Link } from "react-router-dom";
//import MessageTriggers from './components/MessageTriggers';
import { MdInfo } from "react-icons/md";



const ConfigPagamento = () => {

  const [integration, setIntegration] = useState('MercadoPago');
  const [paymentMethod, setPaymentMethod] = useState('Pix');
  const [publicKey, setPublicKey] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState({});
  const [isConfiguredMsgConfirmPay, setIsConfiguredMsgConfirmPay] = useState(false);
  const [styleCampoLinkMedia, setStyleCampoLinkMedia] = useState('pb-0 opacity-0');
  const [whatsappAdmin, setWhatsappAdmin] = useState(null);

  // PARA CONTROLE DO MODAL
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const abrirModal = (data) => {
    console.log('Entrou na Func abrirModal');
    console.log('data: ', data);
    //console.log('editMsg: ', editMsg);

    setModalData(data);
    onOpen(true);

    // SE FOR UMA MSG DO TIPO MEDIA, ABRE O CAMPO DO LINK
    if (data?.dataMsg?.type === 'image' || data?.dataMsg?.type === 'video') {

      setTimeout(() => {

        //document.querySelector('#pai-link-mulmidia').classList.remove('pb-0');
        //document.querySelector('#pai-link-mulmidia').classList.add('pb-[96px]');
        setStyleCampoLinkMedia('pb-[96px] opacity-100');
      }, 500);
    }
  };


  // Define o estado para armazenar conteúdo do titulo
  const [titulo, setTitulo] = useState('');

  const [mediaType, setMediaType] = useState('text');
  const [linkArq, setLinkArq] = useState('');

  const [editMsg, setEditMsg] = useState(false);

  const [modalData, setModalData] = useState({
    //id_msg: '',
    taskName: '',
    //desc: '',
    intervalType: 'days',
    intervalValue: 1,
    frequency: 1,
    executionTime: '',
    categorySend: 'addCategoria',
    status: true,
    dataMsg: {
      type: mediaType,
      media: linkArq,
      msg: '',
    },
    selectValue: 'text',
    recorrenciaType: 'recorrente',
    diaDisparo: 1,
    addMsgPix: false,
    //selectTypeInterval: 'dias', //dias
  });

  const [isLoading, setIsLoading] = useState(false);

  // Define o conteúdo para o titulo da textarea
  const [tituloCampoTxtArea, setTituloCampoTxtArea] = useState('Mensagem');

  // Define a referência para o textarea
  const textFieldRef = useRef(); // Referência para o TextField

  // Define o estado para controlar se o seletor de emoticons está aberto ou não
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);

  // Define o estado para armazenar a mensagem atual no textarea
  const [message, setMessage] = useState('');

  // Define o estado para armazenar a opção de categoria p/ envio
  const [categorySendState, setCategorySendState] = useState('addCategoria');

  // Define o estado para armazenar o intervalo
  const [intervalValueState, setIntervalValueState] = useState(1);

  // Define o estado para armazenar o tipo de intervalo
  const [intervalTypeState, setIntervalTypeState] = useState('days');

  // Define o estado para armazenar o tipo de recorrencia
  const [recorrenciaTypeState, setRecorrenciaTypeState] = useState('recorrente');

  // Define o estado para armazenar a hora de disparo
  const [executionTimeState, setExecutionTimeState] = useState('');

  // Lista de categorias
  const [categorias, setCategorias] = useState([]);

  // Lista de Serviços
  const [customerServiceOptions, setCustomerServiceOptions] = useState([]);

  // Estado para dia de disparo
  const [diaDisparo, setDiaDisparo] = useState(1);

  // Define estado para gerir o envio de msg com Pix
  const [toggleAddMsgPix, setToggleAddMsgPix] = useState(false);


  // Define o estado para controlar a classe do seletor de emoticons
  const [emojiPickerClass, setEmojiPickerClass] = useState('hidden');

  const [mt_conjEmoticons, setMt_conjEmoticons] = useState(0);

  // Define a função para alternar a visibilidade do seletor de emoticons
  const toggleEmojiPicker = () => {
    if (emojiPickerClass == 'aparecer-pra-baixo') { //isEmojiPickerOpen
      setEmojiPickerClass('');
    } else {
      setIsEmojiPickerOpen(true);

      //const element = document.querySelector('#conj-emoticons');

      setEmojiPickerClass('aparecer-pra-baixo');
      setMt_conjEmoticons('-40%');
    }
  };

  // Define a função para lidar com o clique em um emoticon
  // Adiciona o emoticon clicado na posição do cursor no textarea e fecha o seletor de emoticons
  const handleEmojiClick = (emoji) => {
    if (textFieldRef.current) {
      const { selectionStart, value } = textFieldRef.current; //.querySelector('textarea'); //textareaRef.current;
      setMessage(value.slice(0, selectionStart) + emoji + value.slice(selectionStart));
      //setIsEmojiPickerOpen(false);
    }
  };

  // Define a função para lidar com a mudança no textarea
  // Atualiza a mensagem com o valor atual do textarea e mantém o foco no textarea
  const handleTextChange = (event) => {
    setMessage(event.target.value);
    //textareaRef.current.focus();
  };

  // DEFINE O TIPO DE MENSAGEM DE ACORDO COM A OPÇÃO ESCOLHIDA NO SELECT TIPO MSG
  const handleSelectTipoMsgChange = (e) => {

    const tipoMsg = e.target.value;
    console.log('Select tipo msg: ', tipoMsg);

    if (tipoMsg === 'text') {

      // DEFINE O TITULO DO CAMPO 'TEXTAREA'
      setTituloCampoTxtArea('MENSAGEM');

      // RESETA OS DADOS P/ MSG DO MEDIA
      setMediaType('text');
      setLinkArq('');

      /*setModalData((prevState) => ({
        ...prevState,
        dataMsg: {
          type: tipoMsg,
          media: modalData?.dataMsg?.media || '',
          msg: modalData?.dataMsg?.msg || '',
        },
      }));*/

      //document.querySelector('#pai-link-mulmidia').classList.add('pb-0');
      //document.querySelector('#pai-link-mulmidia').classList.remove('pb-[96px]');
      setStyleCampoLinkMedia('pb-[0px] opacity-0');
    }
    else if (tipoMsg === 'image' || tipoMsg === 'video') {

      // DEFINE O TITULO DO CAMPO 'TEXTAREA'
      setTituloCampoTxtArea('LEGENDA');

      // DEFINE OS DADOS P/ MSG DO MEDIA
      /*setModalData((prevState) => ({
        ...prevState,
        dataMsg: {
          type: tipoMsg,
          media: modalData?.dataMsg?.media || '',
          msg: modalData?.dataMsg?.msg || '',
        },
      }));*/

      setMediaType(tipoMsg);
      //setLinkArq(false);

      //document.querySelector('#linkMulmidia').classList.remove('hidden');

      //const element = document.querySelector('#linkMulmidia');
      /*element.style['margin-top'] = '-40%';
      element.style['display'] = '';*/

      //document.querySelector('#pai-link-mulmidia').classList.remove('pb-0');
      //document.querySelector('#pai-link-mulmidia').classList.add('pb-[96px]');
      //element.classList.add('aparecer-pra-baixo');
      setStyleCampoLinkMedia('pb-[96px] opacity-100');

      //element.classList.add('mt-0');
      //element.classList.remove('mt-[-40%]');
    }
  };

  // DEFINE O TIPO DE INTERVALO DE ACORDO COM A OPÇÃO ESCOLHIDA NO SELECT TIPO DE INTERVALO
  const handleSelectTipoIntervaloChange = (e) => {

    const tipoIntervalo = e.target.value;
    console.log('Select tipo intervalo: ', tipoIntervalo);

    // DEFINE OS DADOS DO TIPO DE INTERVALO
    setModalData((prevState) => ({
      ...prevState,
      intervalType: tipoIntervalo,
    }));

    setIntervalTypeState(tipoIntervalo);
  };// Fim: handleSelectTipoIntervaloChange

  // DEFINE O TIPO DE RECORRENCIA DE ACORDO COM A OPÇÃO ESCOLHIDA NO SELECT TIPO DE RECORRENCIA
  const handleSelectTipoRecorrencia = (e) => {

    const tipoRecorrencia = e.target.value;
    console.log('Select tipo recorrencia: ', tipoRecorrencia);

    // DEFINE OS DADOS DO TIPO DE RECORRENCIA
    setModalData((prevState) => ({
      ...prevState,
      recorrenciaType: tipoRecorrencia,
    }));

    setRecorrenciaTypeState(tipoRecorrencia);
  };// Fim: handleSelectTipoRecorrencia

  // DEFINE O TIPO DE CATEGORIA DE ENVIO DE ACORDO COM A OPÇÃO ESCOLHIDA NO SELECT TIPO DE CATEGORIA
  const handleSelectTipoCategoriaChange = (e) => {

    const tipoCategoria = e.target.value;
    console.log('Select tipo categoria: ', tipoCategoria);

    // DEFINE OS DADOS DO TIPO DE CATEGORIA
    setModalData((prevState) => ({
      ...prevState,
      categorySend: tipoCategoria,
    }));

    setCategorySendState(tipoCategoria);
  };// Fim: handleSelectTipoCategoriaChange

  // DEFINE O DIA DE DISPARO DE ACORDO COM A OPÇÃO ESCOLHIDA NO SELECT
  const handleSelectDiaDisparoChange = (e) => {

    const diaDisparo = e.target.value;
    console.log('Select dia disparo: ', diaDisparo);

    // DEFINE OS DADOS DO TIPO DE INTERVALO
    setModalData((prevState) => ({
      ...prevState,
      diaDisparo: diaDisparo,
    }));

    setDiaDisparo(diaDisparo);
  };// Fim: handleSelectTipoIntervaloChange



  const handleAddMsgPix = () => {
    const value_toggleAddMsgPix = toggleAddMsgPix ? false : true;
    setToggleAddMsgPix(value_toggleAddMsgPix);
    setModalData((prevState) => ({
      ...prevState,
      addMsgPix: value_toggleAddMsgPix,
    }));
  };


  const tipoMsgSelect = useRef();
  const tipoIntervaloSelect = useRef();
  const tipoCategoriaSelect = useRef();
  const tipoRecorrenciaSelect = useRef();
  const diaDisparoSelect = useRef();


  // =============================================================================================================================
  const editConfigMessages = (editMsg) => {
    setIsLoading(true);

    let body = { tasks: [] } //configMessages;

    // VERIFICA SE É UMA EDIÇÃO OU ADIÇÃO DE UMA MSG
    console.log('body(criar agendamento): ', body);

    if (editMsg !== false) {
      body.tasks[editMsg] = modalData;
      setEditMsg(false);
    }
    else body.tasks.push(modalData);

    console.log('body: ', body);
    api
      .post('schedule/create-tasks/' /*+ body._id*/, body)
      .then((response) => {
        Toast.fire({
          icon: 'success',
          title: 'Sua mensagem foi Agendada com sucesso!',
        });
        onClose();
        //setTimeout(() => {
        console.log('Dados retornado: ', response.data);
        //handleUpdateConfigMessages(response.data);
        //}, 1000);

        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });
  };// Fim Func editConfigMessages



  // ========================================================================================================================
  const handleSaveMessage = () => {

    if (!message || message == '') {
      Toast.fire({
        icon: 'error',
        title: 'Você precisa adicionar algum texto a mensagem de confirmação de pagamento!',
      });
      return
    }

    setConfirmationMessage({
      type: mediaType,
      media: linkArq,
      msg: message,
    });

    setIsConfiguredMsgConfirmPay(true);
    //setIsModalOpen(false);
    onClose();
  };


  // ------------------------------------------------------------------------------------------------------------------------
  const handleSubmit = (e) => {

    if (!isConfiguredMsgConfirmPay) {
      console.log('Mensagem de confirmação de pagamento não foi configurada ainda!');
      Toast.fire({
        icon: 'error',
        title: 'Mensagem de confirmação de pagamento não foi configurada ainda!',
      });
      //return false
    }


    // ORGANIZA OS DADOS P/ ENVIO
    const body = {
      integration: integration,
      paymentMethod: paymentMethod,
      publicKey: publicKey,
      accessToken: accessToken,
      confirmationMessage: confirmationMessage,
      whatsappAdmin: whatsappAdmin,
    }


    // ENVIA OS DADOS DAS "CONF. DE PAGAMENTO"
    api
      .post('payment-settings', body)
      .then((response) => {
        Toast.fire({
          icon: 'success',
          title: 'Dados enviados com sucesso!',
        });

      })
      .catch((error) => {
        console.error('Erro ao enviar dados de conf. De pagamento:', error);
        Toast.fire({
          icon: 'error',
          title: 'Erro ao salvar config. de pagamento!',
        });
      });

    e.preventDefault();
    // Lógica para enviar o formulário
    console.log({
      integration,
      paymentMethod,
      publicKey,
      accessToken,
      confirmationMessage,
      whatsappAdmin,
    });
  };





  // ============================================================================================================================
  useEffect(() => {

    // OBTEM OS DADOS DAS "CONF. DE PAGAMENTO"
    api
      .get('payment-settings')
      .then((response) => {
        const item = response.data;

        console.log('Dados conf. De pagamento: ', item);

        if (item?.integration) {
          setIntegration(item.integration);
          setPaymentMethod(item.paymentMethod);
          setPublicKey(item.publicKey);
          setAccessToken(item.accessToken);
          setConfirmationMessage(item.confirmationMessage);
          setIsConfiguredMsgConfirmPay(true);
          setWhatsappAdmin(item.whatsappAdmin);
        }

      })
      .catch((error) => {
        console.error('Erro ao obter dados de conf. De pagamento:', error);
        Toast.fire({
          icon: 'error',
          title: 'Erro ao obter dados de conf. De pagamento!',
        });
      });


  }, []);






  // ============================================================================================================================
  return (

    <Card extra={'w-full h-full sm:overflow-auto px-6 mt-1'}>



      <Modal /*data={data}*/ isOpen={isOpen} onClose={onClose} className="!z-[1010]">
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="!z-[1002] !m-auto !w-max min-w-auto _!max-w-[85%] top-[12vh] sm:top-[12vh] sm:min-w-[700px]">
          <ModalBody className=''>
            <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[700px] flex !z-[1004] max-h-[450px] overflow-y-auto  md:max-h-[600px]">




              {/* -------------------------------- LINHA DE DIVISÃO -------------------------------- */}
              {/*}<hr className="border-gray-200 dark:!border-white/20 mb-4 mt-5" />{*/}

              {/* -------------------------------- CAMPO LINK DO ARQ MULTIMIDIA -------------------------------- */}
              <div id='pai-link-mulmidia' className={`overflow-hidden transition-all duration-1000 ${styleCampoLinkMedia}`}>
                <div id='linkMulmidia' className="_aparecer-pra-baixo-default transition-all duration-1000" style={{ /*display: 'none',*/ }} >
                  <div className='text-center'>
                    <label
                      htmlFor={''}
                      className="ml-0 text-sm font-bold text-navy-700 dark:text-white"
                    >
                      LINK ARQUIVO MULTIMIDIA
                    </label>
                  </div>

                  <InputField
                    placeholder="http://imagem.jpg ..."
                    id="inputLinkMulmidia"
                    type="text"
                    extra="w-full"
                    value={linkArq}
                    onChange={(e) => {
                      /*setModalData((prevState) => ({
                        ...prevState,
                        dataMsg: {
                          type: modalData.dataMsg.type,
                          media: e.target.value,
                          msg: modalData.dataMsg.msg,
                        },
                      }));*/

                      setLinkArq(e.target.value);
                    }}
                  />
                </div>
              </div>

              {/* ----------------------------------- CAMPO 'TEXTAREA' DA MSG ----------------------------------- */}
              <div id="contentMsg" className="mt-[-80px]">
                <TextField
                  label={tituloCampoTxtArea}
                  textFieldRef={textFieldRef}
                  value={message}
                  onChange={(e) => {
                    setModalData((prevState) => ({
                      ...prevState,
                      //msg: e.target.value,
                      dataMsg: {
                        type: modalData?.dataMsg?.type,
                        media: modalData?.dataMsg?.media || '',
                        msg: e.target.value,
                      },
                    }));
                    handleTextChange(e);
                  }
                  }
                />

                {/* Renderiza o textarea com a mensagem atual e o manipulador de mudança */}
                {/*<textarea ref={textareaRef} value={message} onChange={handleTextChange} />*/}

                <div className='flex items-center'>

                  {/* ---------------------------- BOTÃO ABRIR/FECHAR O SELETOR DE EMOTICONS ---------------------------- */}
                  <button onClick={toggleEmojiPicker}>
                    <div className='p-2 h-[50px]'>
                      <MdInsertEmoticon className="h-8 w-8 transition-all hover:h-9 hover:w-9" />
                    </div>
                  </button>



                  {/* ----------------------------------- SELECT DO TIPO DE MENSAGEM ----------------------------------- */}
                  <select
                    onChange={handleSelectTipoMsgChange}
                    id="selectTipoMsg"
                    class="ml-auto mr-2 flex w-auto items-center justify-center rounded-xl border bg-white/0 p-1 text-sm outline-none"
                    ref={tipoMsgSelect}
                    value={mediaType} //{modalData.selectValue}
                  >
                    <option className="dark:bg-gray-700" value="text">Mens. De texto</option>
                    <option className="dark:bg-gray-700" value="image">Mens. De imagem</option>
                    <option className="dark:bg-gray-700" value="video">Mens. De vídeo</option>
                    {/* Adicione mais opções conforme necessário */}
                  </select>

                </div>

                {/* Se o seletor de emoticons estiver aberto, renderiza os emoticons */}
                <div className='overflow-hidden'>
                  {isEmojiPickerOpen && (
                    <div
                      id='conj-emoticons'
                      className={`rounded-xl bg-[#ffffff17] p-2 w-auto h-auto aparecer-pra-baixo-default ${emojiPickerClass}`}
                      style={{ marginTop: mt_conjEmoticons }}
                    >
                      {/* Para cada emoticon, renderiza um botão que, quando clicado, adiciona o emoticon à mensagem */}
                      {['😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '😊', '😇', '🙂', '🙃',
                        '😉', '😌', '😍', '🥰', '😘', '😋', '😛', '😝', '😜', '🤪', '🤨', '🧐', '🤓', '😎', '🤩',
                        '🥳', '😕', '🙁', '🥺', '😭'].map((emoji) => (
                          <button key={emoji} className='p-[2px] transition-all' onClick={() => handleEmojiClick(emoji)}>
                            {emoji}
                          </button>
                        ))}
                    </div>
                  )}
                </div>
              </div>




              {/* --------------------------- EXIBE LISTA DE FRASES/PALAVRAS GATILHOS --------------------------- */}
              {/*}<MessageTriggers />{*/}

              {/* ---------------------------------------- EXIBE BOTÕES ---------------------------------------- */}
              <div className="mt-10 flex justify-end gap-2">
                <button
                  onClick={onClose}
                  className="linear rounded-xl border-2 border-red-500 px-5 py-3 text-base font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                >
                  Cancelar
                </button>
                <button
                  onClick={
                    () => handleSaveMessage() //editConfigMessages(editMsg)
                  }
                  className="linear rounded-xl bg-brand-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                >
                  Salvar
                </button>
              </div>

            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>






      {/* ====================================================================================================================== */}
      <form onSubmit={handleSubmit} className="p-6 max-w-lg mx-auto">

        {/* ---------------------------------------- SELECT - INTEGRAÇÃO ----------------------------------------*/}
        <div className="mb-4">
          <label className="text-sm text-navy-700 font-bold dark:text-white">Integração</label>
          <select
            value={integration}
            onChange={(e) => setIntegration(e.target.value)}
            className="mr-auto mt-2 flex _w-auto items-center justify-center rounded-xl border bg-white/0 p-1 text-sm outline-none w-full dark:text-white"
          >
            <option className="dark:bg-gray-700" value="MercadoPago">Mercado Pago</option>
          </select>
        </div>


        {/* ---------------------------------------- SELECT - METODO PAGAMENTO ----------------------------------------*/}
        <div className="mb-4">
          <label className="text-sm text-navy-700 font-bold dark:text-white">Metodo de pagamento</label>
          <select
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
            className="mr-auto mt-2 flex _w-auto items-center justify-center rounded-xl border bg-white/0 p-1 text-sm outline-none w-full dark:text-white"
          >
            <option className="dark:bg-gray-700" value="Pix">Pix</option>
          </select>
        </div>


        {/* ----------------------------------- INFO COMO CRIAR APLICAÇÃO NO MERCADO PAGO ---------------------------------- */}
        <div
          className={'mb-4 bg-[#8d8d8d1c] p-3 rounded-xl'}
        >
          <span>
            Acesse o link para saber mais como criar uma aplicação no Mercado Pago:
          </span>
          <Link
            className={'ml-1'}
            target={"_blank"}
            to='https://www.mercadopago.com.br/developers/pt/docs/your-integrations/dashboard'
          >
            <b>clique aqui</b>
          </Link>
        </div>


        {/* ---------------------------------------- LINK WEBHOOK ---------------------------------------- */}
        <div
          className={'_flex mb-4 bg-[#8d8d8d1c] p-3 rounded-xl'}
        >
          <div
            className='mb-5'
          >
            <span>
              Para que a plataforma possa indentificar quando um pagamento é recebido no Mercado Pago,
              é necessario adicionar o endereço de Webhook abaixo a sua aplicação do Mercado Pago. <br />
              Mais informações:
            </span>
            <Link
              className={'ml-1'}
              target={"_blank"}
              to='https://www.mercadopago.com.br/developers/pt/docs/your-integrations/notifications/webhooks'
            >
              <b>clique aqui</b>
            </Link>
          </div>

          <InputField
            label='Webhhok'
            id="inputWebhook"
            type="text"
            extra="w-full"
            value='https://api.clickfacil.top/pg-interacoes/process_pg'
            disabled={true}
          />
        </div>


        {/* -------------------------------------------------------------------------------------------------------------------- */}
        <div className='flex'>
          <span className="text-sm text-navy-700 font-bold dark:text-white">
            Public Key
          </span>
          <Link
            className={'ml-1'}
            target={"_blank"}
            to='https://www.mercadopago.com.br/developers/pt/docs/your-integrations/credentials'
          >
            <MdInfo className='h-5 w-5' />
          </Link>
        </div>

        <PassInput
          title=''
          value={publicKey}
          handleOnchange={setPublicKey}
          placeholder={''}
        />


        {/* -------------------------------------------------------------------------------------------------------------------- */}
        <div className='flex'>
          <span className="text-sm text-navy-700 font-bold dark:text-white">
            Access Token
          </span>
          <Link
            className={'ml-1'}
            target={"_blank"}
            to='https://www.mercadopago.com.br/developers/pt/docs/your-integrations/credentials'
          >
            <MdInfo className='h-5 w-5' />
          </Link>
        </div>

        <PassInput
          title=''
          value={accessToken}
          handleOnchange={setAccessToken}
          placeholder={''}
        />



        {/* ------------------------------------ INPUT WHATSAPP ADMIN  ------------------------------------ */}
        <InputField
          label='Whatsapp que receberá notificação de pagamento(opcional)'
          id="inputWhatsappAdmin"
          placeholder='551199998888 ...'
          type="number"
          extra="w-full"
          value={whatsappAdmin || ''}
          onChange={(e) => setWhatsappAdmin(e.target.value)}
        />




        {/* ------------------------------------ STATUS MENSAGEM DE CONFIRMAÇÃO DE PAGAMENTO  ------------------------------------*/}
        <div className="mt-5 mb-4">
          <p className="text-sm text-navy-700 font-bold dark:text-white">
            MENSAGEM DE CONFIRMAÇÃO DE PAGAMENTO P/ O CLIENTE:
          </p>
          <div className="text-sm text-navy-700 dark:text-white">
            <span className='font-bold'>Status: </span>
            <span
              className={`${isConfiguredMsgConfirmPay ? 'text-blue-500' : 'text-red-500'}`}
            >{isConfiguredMsgConfirmPay ? 'Configurada' : 'Não configurada'}</span>
          </div>
        </div>

        {/* ------------------------------------ BOTÃO - CONFIG. MENSAGEM DE CONFIRMAÇÃO DE PAGAMENTO  ------------------------------------*/}
        <div>
          <Button
            className='linear rounded-xl bg-brand-500 px-5 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30'
            onClick={
              () => {

                if (confirmationMessage?.type) {
                  if (confirmationMessage.type === 'text') {

                    // DEFINE O TITULO DO CAMPO 'TEXTAREA'
                    setTituloCampoTxtArea('MENSAGEM');
                    setMessage(confirmationMessage.msg);

                    // RESETA OS DADOS P/ MSG DO MEDIA
                    setMediaType('text');
                    setLinkArq('');
                    handleSelectTipoMsgChange({ target: { value: confirmationMessage.type } });
                  }
                  else {
                    // DEFINE O TITULO DO CAMPO 'TEXTAREA'
                    setTituloCampoTxtArea('LEGENDA');
                    setMessage(confirmationMessage.msg);

                    // RESETA OS DADOS P/ MSG DO MEDIA
                    setMediaType(confirmationMessage.type);
                    setLinkArq(confirmationMessage.media);
                    handleSelectTipoMsgChange({ target: { value: confirmationMessage.type } });
                  }
                }

                onOpen();
              } //</div>setIsModalOpen(true)
            }
          >
            Configurar
          </Button>
        </div>


        {/* ------------------------------------ BOTÃO - ENVIO FORM  ------------------------------------*/}
        { }<div
          className='mx-auto mt-10 text-center'
        >
          <Button
            type="submit"
            colorScheme="green"
            className="linear rounded-xl bg-green-500 px-5 py-2 text-base font-medium text-white transition duration-200 hover:bg-green-700 active:bg-green-300 dark:bg-green-500 dark:text-white dark:hover:bg-green-700 dark:active:bg-green-300"
          >
            Enviar
          </Button>
        </div>{ }

      </form>

    </Card>

  );
};

export default ConfigPagamento;
