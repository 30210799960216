import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import Card from 'components/card';
import { Bars } from 'react-loader-spinner';
import debounce from 'lodash/debounce';

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import {
  MdAdd,
  MdDelete,
  MdEdit,
  MdMessage,
  MdOutlineRepeat,
  MdSearch,
  MdCached,
  MdPowerSettingsNew,
} from 'react-icons/md';
import { useDisclosure } from '@chakra-ui/hooks';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import InputField from '../components/InputField';
import api from 'api/api';
import Toast from 'components/toast';
import Swal from 'sweetalert2';
import Datepicker from 'react-tailwindcss-datepicker';
import SwitchField from './SwitchField';
import TextField from './TextField';
import dayjs from 'dayjs';
import RenovationModal from './RenovationModal';
import SendMessageModal from './SendMessageModal';

var validateDateBefore;

const CheckTable = React.memo(({
  columnsData,
  tableData,
  totalPages,
  currentPage,
  setCurrentPage,
  onPageChange,
  itemsPerPage,
  setItemsPerPage,
  fetchCustomers,

  handleSearchChange,
  searchTerm,

  carregando,
  visibleItemsCount,
  totalItemsCount,
}) => {

  // ===========================================================================================================================
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [customerName, setCustomerName] = useState('');
  const [customerWhatsApp, setCustomerWhatsApp] = useState('');
  const [customerLogin, setCustomerLogin] = useState('');
  const [customerPassword, setCustomerPassword] = useState('');
  const [customerDevice, setCustomerDevice] = useState('');
  const [customerMAC, setCustomerMAC] = useState('');
  const [customerKey, setCustomerKey] = useState('');
  const [customerApps, setCustomerApps] = useState('');
  const [customerService, setCustomerService] = useState('');
  const [customerServiceOptions, setCustomerServiceOptions] = useState([]);
  const [customerPlanOptions, setCustomerPlanOptions] = useState([]);
  const [customerPlan, setCustomerPlan] = useState('');
  const [customerComment, setCustomerComment] = useState('');
  const [customerInvoice, setCustomerInvoice] = useState('payed');
  const [customerValidateDate, setCustomerValidateDate] = useState('');
  const [customerSendNotificationOn, setCustomerSendNotificationOn] = useState({
    sendMsg: true,
  });
  const [customerStatus, setCustomerStatus] = useState();
  const [customerId, setCustomerId] = useState('');
  const [modalTitle, setModalTittle] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [openRenovationModal, setOpenRenovationModal] = useState(false);
  const [openSendMessageModal, setOpenSendMessageModal] = useState(false);
  const [dadosCliente, setDadosCliente] = useState();

  const updateParentState = (newValue) => {
    setIsLoading(newValue);
  };

  const [fields, setFields] = useState([]);

  const [totalItems, setTotalItems] = useState(0);
  const [visibleItems, setVisibleItems] = useState(0);
  //setVisibleItems(tableData.length);

  const customerNameRef = useRef();
  const customerWhatsAppRef = useRef();

  // ---------------------------------------------------------------------------------------------------------------------------
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: Number.MAX_SAFE_INTEGER,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = tableInstance;
  //console.log('page: ', page);
  //console.log('tableData: ', tableData);
  //console.log('Dados: ', data);
  //console.log('columns: ', columns);

  /*const clienteEspecifico = tableData.filter( c => c.name == "jon dev");
  console.log('clienteEspecifico: ', clienteEspecifico);*/


  // ---------------------------------------------------------------------------------------------------------------------------
  const handleCheckboxChange = (event, key) => {

    const { checked } = event.target;
    setCustomerSendNotificationOn((prevState) => ({
      ...prevState,
      [key]: checked,
    }));

    //console.log('customerSendNotificationOn: ', customerSendNotificationOn);

  };// Fim Func handleCheckboxChange


  // ---------------------------------------------------------------------------------------------------------------------------
  const handleRenovationModal = (value) => {
    setCustomerName(value.name);
    setCustomerWhatsApp(value.whatsapp);
    setCustomerService(value.serviceId._id);
    setCustomerPlan(value.planId._id);
    setCustomerInvoice(value.invoice);
    setCustomerValidateDate({
      startDate: value.endDate,
      endDate: value.endDate,
    });
    setCustomerId(value.id);

    // ----------------------------
    // CARREGA OS DADOS DE CADA CAMPO DINÂMICO
    let fields_the_customer = value?.dynamicFields?.dynamicFields || [];
    const fieldsUpdate = fields.map(field => {
      // Encontra o campo equivalente em fields_the_customer com base na chave "label"
      const customerField = fields_the_customer.find(cf => cf.label === field.label) || {};

      // Atualiza o campo atual (field) com as informações do campo equivalente em fields_the_customer
      if (customerField._id) field._id = customerField._id;
      // field.label = customerField.label; // Descomente se quiser sempre atualizar label
      // field.options = customerField.options; // Descomente se quiser sempre atualizar options

      // Atualiza o valor do campo com base nas condições especificadas
      if (customerField.value && field.type === 'text') {
        console.log('field.label: ', field.label);
        console.log('customerField.label: ', customerField.label);
        field.value = customerField.value;
      } else if (customerField.value && field.type === 'select' && field.options.indexOf(customerField.value) !== -1) {
        field.value = customerField.value;
      } else {
        // Se não atender a nenhuma condição, define o valor como uma string vazia
        field.value = '';
      }

      return field; // Retorna o campo atualizado para o novo array fieldsUpdate
    });

    // ----------------------------

    console.log('fieldsUpdate: ', fieldsUpdate);
    setFields(fieldsUpdate);

    setOpenRenovationModal(true);
  };

  // ---------------------------------------------------------------------------------------------------------------------------
  const handleSendMessageModal = (value) => {
    console.log('value: ', value);
    setCustomerWhatsApp(value.whatsapp);
    setDadosCliente(value);
    setOpenSendMessageModal(true);
  };

  // ---------------------------------------------------------------------------------------------------------------------------
  const handleValidateDateChange = (newValue) => {
    setCustomerValidateDate(newValue);
  };

  // ---------------------------------------------------------------------------------------------------------------------------
  const handleFieldsValue = (value, index) => {

    const updatedFields = [...fields];
    updatedFields[index].value = value;
    setFields(updatedFields);
  };

  /*const handleFieldsValue = useCallback((value, index) => {
    // Utilize o useCallback para memoizar a função e evitar recriações desnecessárias
    const updatedFields = [...fields];
    updatedFields[index].value = value;
    setFields(updatedFields);
  }, [fields, setFields]);*/

  //const debouncedHandleFieldsValue = useCallback(debounce(handleFieldsValue, 300), [handleFieldsValue]);

  // ---------------------------------------------------------------------------------------------------------------------------
  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);

    return result;
  };

  // ---------------------------------------------------------------------------------------------------------------------------
  // IMPLEMENTAÇÃO P/ TRABALHAR C/ AS CATEGORIAS

  // Estado para armazenar as categorias escolhidas
  const [categoriasEscolhidas, setCategoriasEscolhidas] = useState([]);
  // Estado para controlar o valor selecionado no select
  const [categoriaSelecionada, setCategoriaSelecionada] = useState('');

  // Lista de categorias (você pode buscar isso de uma API ou de qualquer outra fonte)
  const [categorias, setCategorias] = useState([]);

  // Manipulador de mudança no select
  const handleSelectCategoryChange = (event) => {
    var novaCategoria = event.target.value;

    [novaCategoria] = categorias.filter(c => c.description == novaCategoria);
    const [jáAdicionada] = categoriasEscolhidas.filter(c => c.description == novaCategoria.description);

    // Adiciona a nova categoria à lista de categorias escolhidas
    if (!jáAdicionada) setCategoriasEscolhidas([...categoriasEscolhidas, novaCategoria]);

    // Define a categoria selecionada de volta para a primeira opção
    setCategoriaSelecionada('');
  };

  // Manipulador de clique para remover uma categoria escolhida
  const handleRemoveCategoria = (categoria) => {
    const novasCategorias = categoriasEscolhidas.filter(c => c !== categoria);
    setCategoriasEscolhidas(novasCategorias);
  };


  // ---------------------------------------------------------------------------------------------------------------------------
  // SWITCH "STATUS" CLIENTE
  const toggleStatus = (dadosCliente) => {

    dadosCliente.status = !dadosCliente.status;

    setCustomerStatus(dadosCliente.status);

    editCustomer(dadosCliente);

  };






  // ===========================================================================================================================
  // CARREGA OS DADOS A SEREM ATUALIZADOS
  const openEditForm = (value) => {
    validateDateBefore = value.endDate;

    setModalTittle('Editar');
    setCustomerName(value.name);
    setCustomerWhatsApp(value.whatsapp);
    setCustomerLogin(value.login);
    setCustomerPassword(value.password);
    setCustomerDevice(value.device);
    setCustomerMAC(value.mac);
    setCustomerKey(value.key);
    setCustomerApps(value.apps);
    setCustomerService(value.serviceId._id);
    setCustomerPlan(value.planId._id);
    setCustomerInvoice(value.invoice);
    setCustomerValidateDate({
      startDate: value.endDate,
      endDate: value.endDate,
    });
    setCustomerComment(value.comment);
    setCustomerSendNotificationOn(value.sendNotificationOn);
    setCustomerStatus(value.status);
    setCustomerId(value.id);
    setCategoriasEscolhidas(value.categoryDynamic || []);

    //customerNameRef.current = {value: value.name};
    //customerWhatsAppRef.current = {value: value.whatsapp};

    console.log('value: ', value);
    //console.log('value.dynamicFields: ', value.dynamicFields);
    let fields_the_customer = value?.dynamicFields?.dynamicFields || [];
    console.log('fields_the_customer: ', fields_the_customer);
    //console.log('fields_the_customer[0].value: ', fields_the_customer[0]?.value);
    console.log('fields: ', fields);
    //console.log('customerNameRef: ', customerNameRef.current);
    //console.log('customerWhatsAppRef: ', customerWhatsAppRef.current);


    /*let fieldsUpdate = [];
    for (let i = 0; i < fields.length; i++) {

      //console.log('field: ', fields_the_customer[i]);
      //console.log('field.value: ', fields_the_customer[i]?.value);

      var field = fields[i];

      if (fields_the_customer[i]) {
        if (fields_the_customer[i]._id) field._id = fields_the_customer[i]._id;
        //if (fields_the_customer[i].label) field.label = fields_the_customer[i].label;
        //if (fields_the_customer[i].options) field.options = fields_the_customer[i].options;

        if (fields_the_customer[i].value && field.type == 'text') {

          console.log('field.label: ', field.label);
          console.log('fields_the_customer[i].label: ', fields_the_customer[i].label);
          field.value = fields_the_customer[i].value;
        }
        else if (fields_the_customer[i].value && field.type == 'select' && field.options.indexOf(fields_the_customer[i].value) !== -1) field.value = fields_the_customer[i].value;
        fieldsUpdate.push(field);
      }
      else {
        field.value = '';
        fieldsUpdate.push(field);
      }
    };*/

    // ----------------------------
    const fieldsUpdate = fields.map(field => {
      // Encontra o campo equivalente em fields_the_customer com base na chave "label"
      const customerField = fields_the_customer.find(cf => cf.label === field.label) || {};

      // Atualiza o campo atual (field) com as informações do campo equivalente em fields_the_customer
      if (customerField._id) field._id = customerField._id;
      // field.label = customerField.label; // Descomente se quiser sempre atualizar label
      // field.options = customerField.options; // Descomente se quiser sempre atualizar options

      // Atualiza o valor do campo com base nas condições especificadas
      if (customerField.value && field.type === 'text') {
        console.log('field.label: ', field.label);
        console.log('customerField.label: ', customerField.label);
        field.value = customerField.value;
      } else if (customerField.value && field.type === 'select' && field.options.indexOf(customerField.value) !== -1) {
        field.value = customerField.value;
      } else {
        // Se não atender a nenhuma condição, define o valor como uma string vazia
        field.value = '';
      }

      return field; // Retorna o campo atualizado para o novo array fieldsUpdate
    });

    // ----------------------------

    console.log('fieldsUpdate: ', fieldsUpdate);
    console.log('customerValidateDate:', customerValidateDate);

    setFields(fieldsUpdate);

    onOpen();
  };


  // ---------------------------------------------------------------------------------------------------------------------------
  // RESETA DADOS P/ CRIAÇÃO DE UM NOVO CLIENTE
  const openCreateForm = () => {
    setModalTittle('Adicionar');
    setCustomerName('');
    setCustomerWhatsApp('');
    setCustomerLogin('');
    setCustomerPassword('');
    setCustomerDevice('');
    setCustomerMAC('');
    setCustomerKey('');
    setCustomerApps('');
    setCustomerService('');
    setCustomerPlan('');
    setCustomerInvoice('payed');
    setCustomerValidateDate({
      startDate: '',
      endDate: '',
    });
    setCustomerComment('');
    setCustomerSendNotificationOn({
      sendMsg: true,
      ultimoEnvio: false,
    });
    setCustomerId('');
    setCategoriasEscolhidas([]);

    const fieldsCleaned = fields.map((field) => {

      field.value = field?.options[0] || '';
      //field.options = [];
      return field;
    });

    setFields(fieldsCleaned);

    onOpen();
  };


  // ---------------------------------------------------------------------------------------------------------------------------
  // ENVIA DADOS P/ "CRIAÇÃO" DE UM NOVO CLIENTE
  const submitForm = () => {

    // VERIFICA SE CAMPOS OBRIGATORIOS FORAM PREENCHIDOS
    // Verifica preenchimento do campo "Data de Vencimento"
    if (!customerValidateDate.endDate) {
      Toast.fire({
        icon: 'warning',
        title: 'Atenção! O campo "DATA DE VALIDADE" é obrigatório!',
        timer: 5_000,
      });
      return;
    }
    setIsLoading(true);

    //const name = customerNameRef?.current?.value;
    //const whatsapp = customerWhatsAppRef?.current?.value;

    //console.log('name(ref): ', name);
    //console.log('whatsapp(ref): ', whatsapp);
    console.log('dynamicFields: ', fields);

    const serviceId = document.getElementById('customer.service').value;
    const planId = document.getElementById('customer.plan').value;

    api
      .post('customer', {
        name: customerName, //customerName,
        whatsapp: customerWhatsApp, //customerWhatsApp,
        login: customerLogin,
        password: customerPassword,
        device: customerDevice,
        mac: customerMAC,
        key: customerKey,
        apps: customerApps,
        serviceId: serviceId,
        planId: planId,
        invoice: customerInvoice,
        validateDate: customerValidateDate.endDate,
        sendNotificationOn: {
          sendMsg: customerSendNotificationOn.sendMsg,
          ultimoEnvio: false,
        },
        comment: customerComment,
        categoryDynamic: categoriasEscolhidas[0] ? categoriasEscolhidas : null,
        status: true,
        dynamicFields: { dynamicFields: fields },
      })
      .then((response) => {
        fetchCustomers(currentPage);
        Toast.fire({
          icon: 'success',
          title: 'Seu cliente foi criado com sucesso!',
        });
        onClose();

        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Erro ao criar cliente: ', error);
        Toast.fire({
          icon: 'error',
          title: 'Erro ao criar cliente, tente novamente mais tarde.',
        });
      });


    // ADICIONA A TRANSAÇÃO, SE ESTIVER DEFINIDO COMO "PAGO"
    if (customerInvoice != 'payed') return;

    let dataPlan = customerPlanOptions.find(dataPlan => dataPlan.id === planId);
    let dataService = customerServiceOptions.find(dataService => dataService.id === serviceId);

    //console.log('valorPlan: ', dataPlan.value['$numberDecimal']);
    //console.log('custoService: ', dataService.cost['$numberDecimal']);

    api
      .post('transactions/entry-expense', {
        transactionEntry: {
          description: `Recebimento do cliente '${customerName}', plano ${dataPlan.name}`,
          amount: dataPlan.value['$numberDecimal'],
          type: 'entrada',
          category: 'Sistema',
        },
        transactionExpense: {
          description: `Custo de serviço do cliente '${customerName}', serviço ${dataService.name}`,
          amount: dataService.cost['$numberDecimal'],
          type: 'saída',
          category: 'Sistema',
        },
      })
      .then((response) => {
        console.log('Nova transação de um novo cliente adicionada c/ sucesso!');
      })
      .catch((error) => {
        console.error('Erro ao contabilizar cliente: ', error);
        Toast.fire({
          icon: 'error',
          title: 'Erro ao contabilizar cliente, tente novamente mais tarde.',
        });
      });

  };


  // ---------------------------------------------------------------------------------------------------------------------------
  // ENVIA DADOS P/ "ATUALIZAÇÃO" DE UM CLIENTE
  const editCustomer = (dadosCliente) => {
    setIsLoading(true);

    // SE A DATA DE VENCIMENTO TIVER SIDO ALTERADA, RESETA OS ENVIOS
    //console.log('validateDateBefore: ', validateDateBefore);
    //console.log('customerSendNotificationOn(antes): ', customerSendNotificationOn);

    let customerSendNotificationOn_ = customerSendNotificationOn;
    if (validateDateBefore !== customerValidateDate.endDate) {

      console.log('Data de venc. alterada');

      setCustomerSendNotificationOn((prevState) => ({
        sendMsg: customerSendNotificationOn.sendMsg,
        ultimoEnvio: false,
      }));

      customerSendNotificationOn_ = {
        sendMsg: customerSendNotificationOn.sendMsg,
        ultimoEnvio: false,
      }
    }

    //const name = customerNameRef.current.value;
    //const whatsapp = customerWhatsAppRef.current.value;

    //console.log('customerService:', customerService);
    //console.log('customerPlan:', customerPlan);
    //console.log('dadosCliente: ', dadosCliente);
    console.log('customerSendNotificationOn_: ', customerSendNotificationOn_);

    let dados = dadosCliente ? {
      name: dadosCliente.name,
      whatsapp: dadosCliente.whatsapp,
      login: dadosCliente.login,
      password: dadosCliente.password,
      device: dadosCliente.device,
      mac: dadosCliente.mac,
      key: dadosCliente.key,
      apps: dadosCliente.apps,
      serviceId: dadosCliente.serviceId._id,
      planId: dadosCliente.planId._id,
      invoice: dadosCliente.invoice,
      validateDate: dadosCliente.validate,
      sendNotificationOn: dadosCliente.sendNotificationOn,
      comment: dadosCliente.comment,
      categoryDynamic: dadosCliente.categoryDynamic,
      status: dadosCliente.status,
      dynamicFields: dadosCliente.dynamicFields || { dynamicFields: [] },
    } :

      {
        name: customerName,
        whatsapp: customerWhatsApp,
        login: customerLogin,
        password: customerPassword,
        device: customerDevice,
        mac: customerMAC,
        key: customerKey,
        apps: customerApps,
        serviceId: document.getElementById('customer.service')?.value,
        planId: document.getElementById('customer.plan')?.value,
        invoice: customerInvoice,
        validateDate: customerValidateDate.endDate,
        sendNotificationOn: customerSendNotificationOn_,
        comment: customerComment,
        categoryDynamic: categoriasEscolhidas[0] ? categoriasEscolhidas : null,
        status: customerStatus,
        dynamicFields: {
          dynamicFields: fields
        },
      }

    const customerId_ = dadosCliente ? dadosCliente.id : customerId
    console.log('dados.dynamicFields: ', dados.dynamicFields);
    //console.log('customerId(definida): ', customerId_);

    /*try {
      dados.serviceId = document.getElementById('customer.service').value;
      dados.planId = document.getElementById('customer.plan').value;
    } catch {}*/

    api
      .patch('customer/' + customerId_, dados)
      .then((response) => {
        //console.log(response.data);
        fetchCustomers(currentPage);

        Toast.fire({
          icon: 'success',
          title: 'Seu cliente foi editado com sucesso!',
        });
        onClose();
        setTimeout(() => {
          //window.location.reload();
          // -------------------------------------------------------------------
          // -------------------------------------------------------------------
        }, 1500);

        setIsLoading(false);
      })
      .catch((error) => {
        console.log('Erro ao editar cliente: ', error);
        Toast.fire({
          icon: 'error',
          title: 'Erro ao editar cliente, tente novamente mais tarde.',
        });
      });
  };


  // ---------------------------------------------------------------------------------------------------------------------------
  // SOLICITA CONFIRMAÇÃO P/ DELETAR UM CLIENTE
  const confirmDeleteCustomer = (id) => {
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Não será possivel reverter essa exclusão!',
      icon: 'warning',
      position: 'top',
      showCancelButton: true,
      confirmButtonColor: '#422afb',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, excluir!',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCustomer(id);
      }
    });
  };


  // ---------------------------------------------------------------------------------------------------------------------------
  // DELETA UM CLIENTE
  const deleteCustomer = (id) => {
    setIsLoading(true);
    api
      .delete('Customer/' + id)
      .then((response) => {
        console.log(response.data);

        Toast.fire({
          icon: 'success',
          title: 'Seu cliente foi deletado com sucesso!',
        });

        fetchCustomers(currentPage);
        /*setTimeout(() => {
          window.location.reload();
        }, 1500);*/

        setIsLoading(false);
      })
      .catch((error) => {
        Toast.fire({
          icon: 'error',
          title: 'Erro ao excluir cliente, tente novamente mais tarde.',
        });
      });
  };


  // ---------------------------------------------------------------------------------------------------------------------------
  useEffect(() => {

    // OBTEM OS DADOS DE "SERVIÇOS"
    api
      .get('service')
      .then((response) => {
        const result = response.data.services.map((item) => {
          return {
            name: item.name,
            id: item._id,
            cost: item.cost,
          };
        });
        setCustomerServiceOptions(result);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });

    // OBTEM OS DADOS DOS "PLANOS"
    api
      .get('plan')
      .then((response) => {
        const result = response.data.plans.map((item) => {
          return {
            name: item.name,
            id: item._id,
            value: item.value,
          };
        });
        setCustomerPlanOptions(result);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });

    // OBTEM OS DADOS DAS "CATEGORIAS"
    api
      .get('category')
      .then((response) => {
        const result = response.data.categories.map((item) => {
          return { label: item.label, description: item.description };
        });
        setCategorias(result);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });

    // OBTEM OS DADOS DOS "CAMPOS DINÂMICOS"
    api
      .get('dynamic-fields')
      .then((response) => {
        console.log('response.data(dynamic-fields): ', response.data);
        const result = response.data.map((item) => {
          return { _id: item._id, label: item.label, type: item.type, options: item.options, value: '' };
        });
        setFields(result);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });


  }, []);


  useEffect(() => {
    //console.log('openRenovationModal: ', openRenovationModal);
  }, [openRenovationModal]);







  // ===========================================================================================================================
  // REDERIZAÇÕES NA PAGINA

  return (
    <Card extra={'w-full h-full sm:overflow-auto px-6 mt-1'}>
      <header className="relative flex items-center pt-4">
        <button
          onClick={() => {
            openCreateForm();
          }}
          className="flex items-center justify-center rounded-full bg-brand-500 p-2 text-3xl text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-300 dark:text-white dark:hover:bg-brand-400 dark:active:bg-brand-200 ampliar"
          title='Adicionar cliente'
        >
          <MdAdd />
        </button>
        <button
          onClick={() => {
            fetchCustomers(currentPage);
          }}
          className="flex items-center justify-center rounded-full bg-brand-500 p-2 ml-3 text-3xl text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-cyan-600 dark:text-white dark:hover:bg-cyan-700 dark:active:bg-cyan-600 rotacionar_ampliar"
          title='Atualizar lista'
        >
          <MdCached />
        </button>

        {/*}
        <div className="ml-4 mr-auto">
          <select
            id="service"
            className=" mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
            onChange={(e) => {
              setCurrentPage(1);
              setItemsPerPage(e.target.value);
            }}
          >
            <option className="dark:bg-gray-700" value={10}>
              10 itens por página
            </option>
            <option className="dark:bg-gray-700" value={50}>
              50 itens por página
            </option>
            <option className="dark:bg-gray-700" value={100}>
              100 itens por página
            </option>
          </select>
        </div>
        {*/}

        <div className="flex flex-row items-center space-x-2 ml-auto">
          <MdSearch />
          <InputField
            id="customer.name"
            type="text"
            extra="w-full"
            //value={customerName}
            //onChange={(e) => setCustomerName(e.target.value)}
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Pesquisar..."
          />
        </div>
      </header>





      {/* =================================================     MODAL      =================================================== */}
      <Modal isOpen={isOpen} onClose={onClose} size="full" className="!z-[1010]">
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent
          className="!z-[1002] !w-[inherit] min-w-auto top-[0vh] sm:top-[12vh] sm:min-w-[700px] md:!w-max" //!z-[1002] !m-auto !w-max min-w-auto !max-w-[85%] top-[24vh] sm:top-[12vh] sm:min-w-[700px]   //!z-[1002] !m-auto _!w-full max-w-full sm:min-w-[700px]
          containerProps={{
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            display: 'grid',
            placeContent: 'center',
            zIndex: '5000 !important',
          }}
        >
          <ModalBody
            className='overflow-x-auto'
          >
            <Card extra="px-[30px] pt-[35px] pb-[40px] w-full max-w-full flex !z-[1004] max-h-[450px] overflow-y-auto  md:max-h-[600px]"> {/* px-[30px] pt-[35px] pb-[40px] max-w-[700px] flex !z-[1004] */}
              <h1 className="mb-[20px] text-2xl font-bold">
                {modalTitle} Cliente
              </h1>
              <div className="grid grid-cols-1 gap-2 md:grid-cols-2 w-full">
                {<InputField
                  label="Nome"
                  placeholder="Ex: João Vitor"
                  id="customer.name"
                  type="text"
                  extra="w-full"
                  //ref={customerNameRef}
                  value={customerName} // {customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                />}

                {<InputField
                  label="WhatsApp do Cliente"
                  placeholder="WhatsApp Ex: 554899999999 tudo junto"
                  id="customer.whatsapp"
                  type="text"
                  extra="w-full"
                  //ref={customerWhatsAppRef}
                  value={customerWhatsApp} //{customerWhatsApp}
                  onChange={(e) => setCustomerWhatsApp(e.target.value)}
                />}


                {/** /}
                <InputField
                  label="Login"
                  placeholder="Ex: joao.vitor@email.com"
                  id="customer.login"
                  type="text"
                  extra="w-full"
                  value={customerLogin}
                  onChange={(e) => setCustomerLogin(e.target.value)}
                />
                <InputField
                  label="Senha"
                  placeholder="Digite uma senha"
                  id="customer.password"
                  type="text"
                  extra="w-full"
                  value={customerPassword}
                  onChange={(e) => setCustomerPassword(e.target.value)}
                />
                <InputField
                  label="Dispositivo"
                  placeholder="Ex: celular, computador, tv"
                  id="customer.device"
                  type="text"
                  extra="w-full"
                  value={customerDevice}
                  onChange={(e) => setCustomerDevice(e.target.value)}
                />
                <InputField
                  label="Endereço MAC"
                  placeholder="ID do dispositivo"
                  id="customer.mac"
                  type="text"
                  extra="w-full"
                  value={customerMAC}
                  onChange={(e) => setCustomerMAC(e.target.value)}
                />
                <InputField
                  label="Key"
                  placeholder="Chave"
                  id="customer.key"
                  type="text"
                  extra="w-full"
                  value={customerKey}
                  onChange={(e) => setCustomerKey(e.target.value)}
                />
                <InputField
                  label="Aplicativos"
                  placeholder="Lista dos aplicativos"
                  id="customer.apps"
                  type="text"
                  extra="w-full"
                  value={customerApps}
                  onChange={(e) => setCustomerApps(e.target.value)}
                />{/**/}




                {/* --------------------------------------------------------------------------------------------------------- */}
                {/* Mapeia os campos dinâmicos da matriz fields */}
                {fields.map((field, index) => (
                  <div key={index} className={`w-full`}>
                    {field.type === 'text' && (
                      <InputField
                        label={field.label}
                        placeholder={`...`}
                        type="text"
                        extra="w-full"
                        value={field.value || ''}
                        onChange={(e) => handleFieldsValue(e.target.value, index)}
                      //onChange={() => {console.log('onChangeVazio() executada')}}
                      />
                    )}
                    {field.type === 'select' && (
                      <div
                        className={`w-full`}
                        key={index}
                      >

                        <label className="text-sm font-bold text-navy-700 dark:text-white">
                          {field.label}
                        </label>
                        <select
                          className={"mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none " +
                            "dark:!border-white/10"}
                          value={field.value || ''}
                          onChange={(e) => handleFieldsValue(e.target.value, index)}
                        >
                          {field.options.map((option, optionIndex) => (
                            <option key={optionIndex} className="dark:bg-gray-700" value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                ))}


                <div className={`w-full`}>
                  <label
                    for="invoice"
                    class="text-sm font-bold text-navy-700 dark:text-white"
                  >
                    Serviço
                  </label>
                  <select
                    id="customer.service"
                    class=" mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                    value={customerService}
                    onChange={(e) => setCustomerService(e.target.value)}
                  >
                    {customerServiceOptions.map((option) => (
                      <option className="dark:bg-gray-700" value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={`w-full`}>
                  <label
                    for="invoice"
                    class="text-sm font-bold text-navy-700 dark:text-white"
                  >
                    Plano
                  </label>
                  <select
                    id="customer.plan"
                    class=" mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                    value={customerPlan}
                    onChange={(e) => setCustomerPlan(e.target.value)}
                  >
                    {customerPlanOptions.map((option) => (
                      <option className="dark:bg-gray-700" value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>

              </div>


              <div className="mt-2 grid grid-cols-1 gap-2  md:grid-cols-3">

                <div className={`w-full`}>
                  <label
                    for="invoice"
                    class="text-sm font-bold text-navy-700 dark:text-white"
                  >
                    Fatura
                  </label>
                  <select
                    id="customer.invoice"
                    class=" mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                    value={customerInvoice}
                    onChange={(e) => setCustomerInvoice(e.target.value)}
                  >
                    <option className="dark:bg-gray-700" value="payed">Pago</option>
                    <option className="dark:bg-gray-700" value="pending">Pendente</option>
                  </select>
                </div>
                <div className={`w-full`}>
                  <label
                    for="invoice"
                    class="text-sm font-bold text-navy-700 dark:text-white"
                  >
                    Data de Validade
                  </label>
                  <Datepicker
                    value={
                      (() => {
                        let dataValidade = new Date(customerValidateDate?.endDate);
                        /*console.log('dataValidade(antes)', dataValidade);
                        let diaValidade = dataValidade.getDate();
                        dataValidade = new Date(dataValidade.setDate(diaValidade + 1));
                        console.log('dataValidade(antes)', dataValidade);*/
                        return {
                          startDate: dataValidade,
                          endDate: dataValidade
                        }
                      })()
                    }
                    startFrom={new Date(customerValidateDate.endDate)}
                    inputId={'customer.validateDate'}
                    onChange={handleValidateDateChange}
                    placeholder="Escolha a data"
                    inputClassName="bg-white/0 mt-2 h-12 w-full rounded-xl border p-3 text-sm outline-none"
                    toggleClassName="absolute mt-5 ml-[-30px]"
                    asSingle={true}
                    displayFormat={'DD/MM/YYYY'}
                    required
                  />
                </div>
                <div className={`w-full ml-1`}>
                  <label
                    for="invoice"
                    class="text-sm font-bold text-navy-700 dark:text-white"
                  >
                    Notificações de Vencimento
                  </label>
                  <div className={`w-100 mt-1`}>
                    <SwitchField
                      desc="Enviar"
                      id="customer.sendNotification"
                      checked={customerSendNotificationOn.sendMsg}
                      onChange={(e) => handleCheckboxChange(e, 'sendMsg')}
                    />
                  </div>
                </div>
              </div>

              <div className='mt-4'>

                <div className='text-center'>
                  <span className='text-sm font-bold text-navy-700 dark:text-white'>Categorias</span>
                </div>

                <div className="mt-2 grid grid-cols-1 gap-2 max-w-[622px]  md:grid-cols-2">

                  <div className={`w-full`}>
                    {/*<label
                    for="invoice"
                    class="text-sm font-bold text-navy-700 dark:text-white"
                  >
                    Categoria
                  </label>*/}
                    <select
                      id="customer.category"
                      class="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                      value={categoriaSelecionada}
                      onChange={handleSelectCategoryChange}
                    >
                      <option className="dark:bg-gray-700" value="addCategoria">Selecione uma categoria</option>
                      {categorias.map((categoria, index) => (
                        <option className="dark:bg-gray-700" key={index} value={categoria.description}>{categoria.description}</option>
                      ))}
                    </select>
                  </div>


                  {/* LISTA DE CATEGORIAS ESCOLHIDAS */}
                  <div className={`_w-full border bg-[#0000000d] border-gray-200 dark:!border-white/10 rounded-xl p-2 max-h-[70px] overflow-auto flex flex-wrap`}>{/* grid grid-cols-1 gap-2 md:grid-cols-2 */}

                    {categoriasEscolhidas.map((categoria, index) => (

                      <div key={index} className='gap-1 mx-2 mt-1' style={{ display: '-webkit-box' }}>
                        {/*console.log('categoria: ', categoria)*/}
                        <div className='w-5 h-5 rounded-[100%] _bg-[#ffce8c]' style={{ background: categoria.label }}></div>
                        <div>{categoria.description}</div>

                        <button
                          onClick={() => {
                            handleRemoveCategoria(categoria)
                          }}
                          className="text-[20px] text-white text-gray-500 hover:cursor-pointer dark:text-gray-300 ampliar"
                          title='Excluir categoria'
                        >
                          <MdDelete />
                        </button>
                      </div>
                    ))}

                  </div>

                </div>

              </div>

              <div className="mt-2 grid grid-cols-1 gap-2">
                <div className={`w-full`}>
                  <TextField
                    label="Observações"
                    id="customer.comments"
                    value={customerComment}
                    onChange={(e) => setCustomerComment(e.target.value)}
                  />
                </div>
              </div>
              <div className="mt-10 flex justify-end gap-2">
                <button
                  onClick={onClose}
                  className="linear rounded-xl border-2 border-red-500 px-5 py-3 text-base font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                >
                  Cancelar
                </button>
                <button
                  onClick={() =>
                    modalTitle === 'Adicionar' ? submitForm() : editCustomer()
                  }
                  className="linear rounded-xl bg-brand-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                >
                  Salvar
                </button>
              </div>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>


      <RenovationModal
        id={customerId}
        name={customerName}
        whatsapp={customerWhatsApp}
        service={customerServiceOptions}
        serviceId={customerService}

        plan={customerPlanOptions}
        planId={customerPlan}
        validateDate={customerValidateDate.endDate}
        fields={fields}
        customerSendNotificationOn={customerSendNotificationOn}
        customerPlanOptions={customerPlanOptions}
        customerServiceOptions={customerServiceOptions}
        customerName={customerName}

        fetchCustomers={fetchCustomers}
        currentPage={currentPage}
        open={openRenovationModal}
        close={() => setOpenRenovationModal(false)}
      />

      <SendMessageModal
        whatsapp={customerWhatsApp}
        dadosCliente={dadosCliente}
        open={openSendMessageModal}
        onClose={() => setOpenSendMessageModal(false)}
        isLoading={updateParentState}
      />




      {/* ==============================================          TABELA          ============================================== */}
      {/* TELA DE CARREGAMENTO */}
      {/*carregando ?*/}
      <div className={`mt-20 mb-20 mx-auto text-center ${carregando ? '' : 'hidden'}`}>

        <Bars
          height="80"
          width="80"
          color="#49006e"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass={`w-[80px] mx-auto`}
          visible={true}
        />

        <div className='mt-5 mx-auto text-center'>
          <span className='text-[25px]'>Carregando...</span>
        </div>

      </div> {/* : */}


      {/* ------------------------------------------------------------------------------------------------------------------------ */}
      {/* TELA DA TABELA */}
      <div
        className={`smallScrollbar mt-8 overflow-x-scroll xl:overflow-x-hidden ${carregando ? 'hidden' : ''}`}
        style={{ maxHeight: '500px', overflow: 'auto' }}
      >
        <table
          {...getTableProps()}
          className="w-full"
          variant="simple"
          color="gray-500"
          mb="24px"
        >
          {/* -------------------------------------      CABEÇALHO - TABELA     ----------------------------------------------- */}
          <thead
            className={`bg-white dark:!bg-navy-800 z-10 sticky top-0`}
          >
            {headerGroups.map((headerGroup, index) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                key={index}
              >
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="border-b border-gray-200 px-8 pb-[10px] _text-start text-center dark:!border-navy-700"
                    key={index}
                  >
                    <div className="text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                      {column.render('Header')}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {/* -------------------------------------      CORPO - TABELA     ----------------------------------------------- */}
          <tbody
            {...getTableBodyProps()}
          >
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={index}
                //className='table'
                >
                  {row.cells.map((cell, index) => {
                    let data = '';

                    // COLUNA - NOME
                    if (cell.column.Header === 'Nome') {
                      data = (
                        <div className="_flex items-center gap-2">
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </p>
                        </div>
                      );
                    }

                    // COLUNA - WHATSAPP
                    else if (cell.column.Header === 'WhatsApp') {
                      data = (
                        <div className="_flex items-center">
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            <div className="rounded-lg bg-gray-100 px-3 py-2 text-xs font-bold uppercase text-navy-700 transition duration-200 dark:bg-white/10 dark:text-white">
                              {cell.value}
                            </div>
                          </p>
                        </div>
                      );
                    }

                    // COLUNA - PLANO
                    else if (cell.column.Header === 'Plano') {
                      data = (
                        <div className="_flex items-center gap-2">
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </p>
                        </div>
                      );
                    }

                    // COLUNA - SERVIÇO
                    else if (cell.column.Header === 'Serviço') {
                      data = (
                        <div className="_flex items-center gap-2">
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </p>
                        </div>
                      );
                    }

                    // COLUNA - FATURA
                    else if (cell.column.Header === 'Fatura') {
                      data = (
                        <div className="_flex items-center gap-2">
                          <p className="text-sm font-bold">
                            {cell.value === 'payed' ? (
                              <div className="rounded-lg px-3 py-2 text-xs text-blue-700 dark:text-blue-500 font-bold uppercase transition duration-200">
                                Pago
                              </div>
                            ) : (
                              <div className="rounded-lg _bg-orange-500 px-3 py-2 text-xs _text-white text-orange-700 dark:text-orange-500 font-bold uppercase transition duration-200 _dark:bg-orange-400">
                                Pendente
                              </div>
                            )}
                          </p>
                        </div>
                      );
                    }

                    // COLUNA - VENCIMENTO
                    else if (cell.column.Header === 'Vencimento') {
                      data = (
                        <div className="_flex items-center gap-2">
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {dayjs(
                              addDays(
                                new Date(
                                  new Date(cell.value).toLocaleString('en', {
                                    timeZone: 'America/Sao_Paulo',
                                  }),
                                ),
                                1,
                              ),
                            ).format('DD/MM/YYYY')}
                          </p>
                        </div>
                      );
                    }

                    // COLUNA - CATEGORIAS
                    else if (cell.column.Header === 'Categorias') {

                      const categorias = cell.value ? (cell.value[0] ? cell.value[0] : null) : null;

                      data = (

                        <div className=' gap-2 max-w-[160px] py-[3px] px-[3px]' style={{ display: categorias ? '-webkit-box' : '', overflow: 'overlay' }}>
                          {/*<div className='w-5 h-5 rounded-[100%] bg-[#ffce8c]' title='Conteudo esportes'></div>
                          <div className='w-5 h-5 rounded-[100%] bg-[#eda1eb]' title='Conteudo infantil'></div>
                          <div className='w-5 h-5 rounded-[100%] bg-[#a2eda1]' title='Assinatura semestral'></div>

                          <div className='w-5 h-5 rounded-[100%] bg-[#ffce8c]' title='Conteudo esportes'></div>
                          <div className='w-5 h-5 rounded-[100%] bg-[#eda1eb]' title='Conteudo infantil'></div>
                          <div className='w-5 h-5 rounded-[100%] bg-[#a2eda1]' title='Assinatura semestral'></div>*/}

                          {/*console.log('categorias:', categorias)*/}

                          {categorias ? cell.value.map((categoria, index) => (
                            <div
                              className='w-5 h-5 rounded-[100%] border border-[#000000]' //shadow-[-2px_3px_5px_0_#000000b3]
                              title={categoria.description}
                              style={{ background: categoria.label }}
                            ></div>
                          ))
                            : <div>-- // --</div>}

                        </div>

                      );

                    }

                    // COLUNA - STATUS VENCIMENTO
                    else if (cell.column.Header === 'Estado venc.') {

                      /*data = (
                      <div>
                        <select
                          //onChange={handleSelectTipoMsgChange}
                          id="selectTipoMsg"
                          class="ml-auto mr-2 flex w-auto items-center justify-center rounded-xl border bg-white/0 p-1 text-sm outline-none"
                          //ref={tipoMsgSelect}
                          //value={mediaType} //{modalData.selectValue}
                        >
                          <option className="dark:bg-gray-700" value="clientesAtivos">Ativo</option>
                          <option className="dark:bg-gray-700" value="clientesVencidos">Vencido</option>
                          <option className="dark:bg-gray-700" value="clientesDesativados">Desativado</option>
                        </select>
                      </div>
                      );*/


                      data = (
                        <div className="_flex items-center gap-2">
                          {dayjs(
                            addDays(
                              new Date(
                                new Date(cell.value).toLocaleString('en', {
                                  timeZone: 'America/Sao_Paulo',
                                }),
                              ),
                              1,
                            ),
                          ).format('DD/MM/YYYY') ===
                            dayjs(
                              new Date(
                                new Date().toLocaleString('en', {
                                  timeZone: 'America/Sao_Paulo',
                                }),
                              ),
                            ).format('DD/MM/YYYY') ? (
                            <div className="rounded-lg text-orange-500 px-3 py-2 text-xs font-bold uppercase _text-white transition duration-200 dark:text-orange-400">
                              Vence Hoje
                            </div>
                          ) : new Date(
                            new Date(cell.value).toLocaleString('en', {
                              timeZone: 'America/Sao_Paulo',
                            }),
                          ).setHours(0, 0, 0, 0) <
                            new Date(
                              new Date().toLocaleString('en', {
                                timeZone: 'America/Sao_Paulo',
                              }),
                            ).setHours(0, 0, 0, 0) ? (
                            <div className="rounded-lg text-red-500 px-3 py-2 text-xs font-bold uppercase _text-white transition duration-200 dark:text-red-400">
                              Vencido
                            </div>
                          ) : (
                            <div className="rounded-lg text-green-500 px-3 py-2 text-xs font-bold uppercase _text-white transition duration-200 dark:text-green-950">
                              Dentro da validade
                            </div>
                          )}
                        </div>
                      );
                    }

                    // COLUNA - BOTÕES AÇÃO
                    else if (cell.column.Header === 'Ação') {
                      let statusCliente = row.original.status;
                      statusCliente = statusCliente === false ? false : (statusCliente === true ? true : true);
                      data = (
                        <div className="flex items-center gap-2">

                          {// BOTÃO ATIVAR/DESATIVAR

                            statusCliente == true ?
                              < button
                                onClick={() => toggleStatus(row.original)}
                                className="flex items-center justify-center rounded-xl p-1 text-2xl text-white transition duration-200 hover:cursor-pointer bg-green-500 hover:bg-green-600 dark:bg-green-600 dark:hover:bg-green-700 dark:text-white  _ampliar"
                                title='Desativar'
                              >
                                <MdPowerSettingsNew />
                              </button> :

                              < button
                                onClick={() => toggleStatus(row.original)}
                                className="flex items-center justify-center rounded-xl p-1 text-2xl text-white transition duration-200 hover:cursor-pointer bg-gray-500 hover:bg-gray-600 dark:bg-[#6a6a6a] dark:hover:bg-gray-800 dark:text-white  _ampliar"
                                title='Ativar'
                              >
                                <MdPowerSettingsNew />
                              </button>
                          }

                          <button
                            onClick={() => openEditForm(row.original)}
                            className="flex items-center justify-center rounded-xl bg-brand-500 p-1 text-2xl text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ampliar"
                            title='Editar cliente'
                          >
                            <MdEdit />
                          </button>
                          <button
                            onClick={() => handleRenovationModal(row.original)}
                            className="flex items-center justify-center rounded-xl bg-teal-500 p-1 text-2xl text-white transition duration-200 hover:cursor-pointer hover:bg-teal-600 active:bg-teal-700 dark:bg-teal-400 dark:text-white dark:hover:bg-teal-300 dark:active:bg-teal-200 ampliar"
                            title='Renovar cliente'
                          >
                            <MdOutlineRepeat />
                          </button>
                          <button
                            onClick={() => handleSendMessageModal(row.original)}
                            className="flex items-center justify-center rounded-xl p-1 text-2xl text-white transition duration-200 hover:cursor-pointer bg-yellow-600 hover:bg-yellow-700 dark:bg-yellow-900 dark:text-white dark:hover:bg-yellow-800 ampliar"
                            title='Enviar msg p/ cliente'
                          >
                            <MdMessage />
                          </button>
                          <button
                            onClick={() => {
                              confirmDeleteCustomer(row.original.id);
                            }}
                            className="flex items-center justify-center rounded-xl bg-red-500 p-1 text-2xl text-white transition duration-200 hover:cursor-pointer hover:bg-red-700 active:bg-brand-700 dark:bg-red-500 dark:text-white dark:hover:bg-red-700 dark:active:bg-red-200 ampliar"
                            title='Excluir cliente'
                          >
                            <MdDelete />
                          </button>
                        </div>
                      );
                    }
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={index}
                        className="pt-[14px] pb-[16px] _px-8 sm:text-[14px] text-center"
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>


        {/* =======================      Pagination     ======================= */}
        {/*}
          <div className="flex justify-center mt-4 mb-4">
            <button
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="bg-gray-200 px-4 py-2 rounded-l-xl disabled:bg-gray-400"
            >
              Anterior
            </button>
            {currentPage > 2 ? (
              <button onClick={() => setCurrentPage(1)}>
                <h2 className="px-4 py-2 border border-solid border-gray-200">
                  1
                </h2>
              </button>
            ) : (
              <></>
            )}
            {currentPage > 1 ? (
              <button onClick={() => setCurrentPage(currentPage - 1)}>
                <h2 className="px-4 py-2 border border-solid border-gray-200">
                  {currentPage - 1}
                </h2>
              </button>
            ) : (
              <></>
            )}
            <h2
              className="bg-gray-200 px-4 py-2 border border-solid border-gray-200"
              id="page"
            >
              {currentPage}
            </h2>
            {currentPage + 1 <= totalPages ? (
              <button onClick={() => setCurrentPage(currentPage + 1)}>
                <h2 className="px-4 py-2 border border-solid border-gray-200">
                  {currentPage + 1}
                </h2>
              </button>
            ) : (
              <></>
            )}
            {currentPage + 2 <= totalPages ? (
              <button onClick={() => setCurrentPage(totalPages)}>
                <h2 className="px-4 py-2 border border-solid border-gray-200">
                  {totalPages}
                </h2>
              </button>
            ) : (
              <></>
            )}
            <button
              onClick={() => onPageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="bg-gray-200 px-4 py-2 rounded-r-xl disabled:bg-gray-400"
            >
              Próximo
            </button>
            </div>
            {*/}

      </div>

      <hr
        style={{ 'border-block-color': '#0000005c' }}
      />
      <div className="_flex _justify-between my-4">
        <span className='font-bold'>{`Exibindo:  `}</span>
        {<span>{`${visibleItemsCount} de ${totalItemsCount} Clientes`}</span>}
        {/*
          <span>{`${visibleItems} de ${totalItems} Clientes`}</span>*/}
      </div>
    </Card >
  );
});

export default CheckTable;
