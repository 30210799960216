// Custom components
import React from 'react';

const InputField = React.forwardRef((props, ref) => {
  const {
    label,
    id,
    extra,
    type,
    placeholder,
    variant,
    state,
    disabled,
    onChange,
    value,
    //ref,
  } = props;

  //console.log('ref: ', ref);

  const inputRef = ref || React.createRef();
  const onChangeVazio = () => {console.log('onChangeVazio() executada')};

  return (
    <div className={`${extra}`}>
      <label
        htmlFor={id}
        className={`text-sm text-navy-700 dark:text-white ${variant === 'auth' ? 'font-medium' : 'font-bold'
          }`}
      >
        {label}
      </label>
      <input
        disabled={disabled}
        type={type}
        id={id}
        key={id}
        placeholder={placeholder}
        className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${disabled === true
            ? '!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]'
            : state === 'error'
              ? 'border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400'
              : state === 'success'
                ? 'border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400'
                : 'border-gray-200 dark:!border-white/10 dark:text-white'
          }`}
        ref={inputRef}
        onChange={onChange || onChangeVazio}
        value={value /*|| ''*/}
      />
      {state === 'error' ? (
        <div style={{ marginLeft: 10 }}>
          <span style={{ fontSize: 12 }} className="text-red-500">
            {label.replace('*', '')} invalido(a), tente novamente
          </span>
        </div>
      ) : null}
    </div>
  );
})

export default InputField;
