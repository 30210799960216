import {
  MdArrowCircleUp,
  MdAttachMoney,
  MdListAlt,
  MdMoneyOff,
  MdOutlinePersonOff,
  MdPerson,
  MdPersonAdd,
  MdSupervisedUserCircle,
  MdCalendarToday,
} from 'react-icons/md';

import {
  BsFillPersonCheckFill,
  BsFillPersonXFill
} from "react-icons/bs";

import {
  FaUser,
  FaUserCheck,
  FaUserXmark,
} from "react-icons/fa6";

import Widget from 'components/widget/Widget';
import Widget2 from './components/Widget2';
import { useEffect, useState, Component } from 'react';
import api from 'api/api';
import CheckTableVencendo from './components/CheckTableVencendo';
import CheckTableExpirado from './components/CheckTableExpirado';
import TableReport from './components/TableReport';

import AreaChart from '../../../components/charts/AreaChart';
import DonutChart from '../../../components/charts/DonutChart';
import BarChart from 'components/charts/BarChart';
import LineChart from 'components/charts/LineChart';
import Moment from 'moment';




{/* =========================================================================================================================== */ }
const Dashboard = () => {
  const [clientsData, setClientsData] = useState({
    total: 0,
    active: 0,
    expired: 0,
    disabled: 0,
  });
  const [moneyData, setMoneyData] = useState({
    profit: 0,
    entry: 0,
    expense: 0,
  });
  const [tableData, setTableData] = useState([]);
  const [clientesVencidos, setClientesVencidos] = useState([]);
  const [tableTransactions, setTableTransactions] = useState([]);
  const [carregando, setCarregando] = useState(true);
  const [balançoMesesAnteriores, setBalançoMesesAnteriores] = useState([]);
  const [listCustomers, setListCustomers] = useState([]);
  const [categoriesNameQtd, setCategoriesNameQtd] = useState([]);
  const [qtdServiçoPorCliente, setQtdServiçoPorCliente] = useState([]);
  const [qtdCadastrosEExclusõesClientes, setQtdCadastrosEExclusõesClientes] = useState([]);


  const columnsDataServices = [
    {
      Header: 'Nome',
      accessor: 'name',
    },
    {
      Header: 'Fatura',
      accessor: 'invoice',
    },
    {
      Header: 'Data de expiração',
      accessor: 'validateDate',
    },
    /*{
      Header: 'WhatsApp',
      accessor: 'whatsapp',
    },*/
    {
      Header: 'Ação',
      accessor: 'action',
    },
  ];

  const colunasTabVencidos = [
    {
      Header: 'Nome',
      accessor: 'name',
    },
    {
      Header: 'Fatura',
      accessor: 'invoice',
    },
    {
      Header: 'Tempo após vencimento',
      accessor: 'tempoApósVencimento',
    },
    {
      Header: 'WhatsApp',
      accessor: 'whatsapp',
    },
  ];

  const columnsReport = [
    {
      Header: 'Descrição',
      accessor: 'description',
    },
    {
      Header: 'Valor',
      accessor: 'amount',
    },
    {
      Header: 'Tipo',
      accessor: 'type',
    },
    {
      Header: 'Data',
      accessor: 'date',
    },
    {
      Header: 'Categoria',
      accessor: 'category',
    },
    {
      Header: 'Ação',
      accessor: 'action',
    },
  ];

  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);

    return result;
  };




  // ---------------------------------------------------------------------------------------------------------------------------
  // PARAMENTROS P/ GRAFICO DE BARRAS

  /*constructor(props) {
    super(props);
    this.state = {
      chartData: [
        {
          name: 'Series 1',
          data: [30, 40, 35, 50, 49, 60, 70, 91, 125]
        },
        {
          name: 'Series 2',
          data: [42, 20, 45, 60, 89, 40, 60, 75, 81]
        }
      ],
      chartOptions: {
        chart: {
          id: 'basic-bar'
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep']
        }
      }
    };
  }*/





  // ---------------------------------------------------------------------------------------------------------------------------
  const fetchCustomers = () => {

    // OBTÉM DADOS DOS CLIENTES
    let profit = 0;
    let entry = 0;
    let expense = 0;
    api
      .get('customer/home')
      .then((response) => {

        // DEFINE A LISTA DE CLIENTES VENCIDOS
        //console.log('Dados clientes: ', response.data);
        //setAllCustomers(response.data.allCustomers);

        //const dataAtual = new Date();
        //console.log('Data atual: ', dataAtual.getDate());
        //console.log('Data atual(UTC): ', dataAtual.getUTCDate());

        const allCustomers = response.data.allCustomers;
        //console.log('allCustomers: ', allCustomers);
        //setListCustomers(allCustomers);

        const clientesVencidos = allCustomers.filter((dadosCliente) => {

          const data1 = Moment(dadosCliente.validateDate);
          const data2 = Moment();  // Data atual

          const diferencaEmDias = data1.diff(data2, 'days');
          const diferencaEmMeses = data1.diff(data2, 'months');

          if (diferencaEmDias < 0) {

            if (diferencaEmMeses < -12) dadosCliente.tempoApósVencimento = `mais de 1 ano...`;
            else if (diferencaEmMeses === -1) dadosCliente.tempoApósVencimento = `1  mês`;
            else if (diferencaEmMeses < -1) dadosCliente.tempoApósVencimento = `${Math.abs(diferencaEmMeses)}  meses`;
            else if (diferencaEmDias === -1) dadosCliente.tempoApósVencimento = `1  dia`;
            else if (diferencaEmDias < -1) dadosCliente.tempoApósVencimento = `${Math.abs(diferencaEmDias)}  dias`;

            return dadosCliente;
          }
          //else return false;

        });
        //console.log('clientesVencidos: ', clientesVencidos);
        setClientesVencidos(clientesVencidos);

        setClientsData({
          total: response.data.totalCustomers,
          active: response.data.totalNotExpired,
          expired: response.data.totalExpired,
          disabled: response.data.totalDisabled,
        });

        // -------------------------------------------------------------------
        /*entry = response.data.allCustomers.reduce(
          (s, i) => (s += Number(i.planId.value['$numberDecimal'])),
          0,
        );

        expense = response.data.allCustomers.reduce(
          (s, i) => (s += Number(i.serviceId.cost['$numberDecimal'])),
          0,
        );

        profit = entry - expense;

        entry = 0;
        expense = 0;
        profit = 0;

        setMoneyData({
          profit: `R$ ${profit.toFixed(2)}`,
          entry: `R$ ${entry.toFixed(2)}`,
          expense: `R$ ${expense.toFixed(2)}`,
        });*/

        // OBTEM AS TRANSAÇÕES E PROCESSA O BALANÇO
        const dataAtual = new Date();
        const startDate = new Date(dataAtual.getFullYear() - 1, dataAtual.getMonth()).toISOString().slice(0, 7);
        const endDate = new Date().toISOString().slice(0, 7);
        fetchTransactionsBalance(startDate, endDate);
        // --------------------------------------------------------------------


        const clientesProximosDoVencimento_5_OuMenosDias =
          allCustomers.filter((dadosCliente) => {
            const data1 = Moment(dadosCliente.validateDate);
            const data2 = Moment();  // Data atual

            const diferencaEmDias = data1.diff(data2, 'days');

            return diferencaEmDias >= 0 && diferencaEmDias <= 4 /*&& (new Date().getDate()+5) !== new Date(dadosCliente.validateDate).getDate()*/;
          })
            .map((dadosCliente) => {
              return {
                //name: dadosCliente.name,
                //invoice: dadosCliente.invoice,
                validateDate: addDays(
                  new Date(dadosCliente.validateDate),
                  1,
                ).toLocaleDateString('pt-BR', {
                  timezone: 'America/Sao_Paulo',
                }),
                //whatsapp: dadosCliente.whatsapp,


                name: dadosCliente.name,
                id: dadosCliente._id,
                whatsapp: dadosCliente.whatsapp,
                plan: `${dadosCliente.planId.name} (R$${parseFloat(
                  dadosCliente.planId.value.$numberDecimal,
                ).toFixed(2)})`,
                /*device: dadosCliente.device,
                mac: dadosCliente.mac,
                key: dadosCliente.key,
                apps: dadosCliente.apps,                
                login: dadosCliente.login,
                password: dadosCliente.password,*/
                service: dadosCliente.serviceId.name,
                invoice: dadosCliente.invoice,
                planId: dadosCliente.planId,
                serviceId: dadosCliente.serviceId,
                comment: dadosCliente.comment,
                endDate: dadosCliente.validateDate.split('T')[0],
                sendNotificationOn: dadosCliente.sendNotificationOn,
                validate: dadosCliente.validateDate.split('T')[0],
                categoryDynamic: dadosCliente.categoryDynamic,
                status: dadosCliente.status,
                dynamicFields: dadosCliente?.dynamicFields || { dynamicFields: [] },
              };
            });

        //console.log('clientesProximosDoVencimento_5_OuMenosDias: ', clientesProximosDoVencimento_5_OuMenosDias);
        setTableData(clientesProximosDoVencimento_5_OuMenosDias);

        // OBTÉM A QTD DE CADA CATEGORIA
        let categoriesCounts = [];
        allCustomers.forEach(customer => {

          //  VERIFICA SE O CLIENTE POSSUI CATEGORIAS
          if (!customer.categoryDynamic) return;

          // Iterar sobre cada categoria do cliente
          customer.categoryDynamic.forEach(dataCategory => {
            // Verificar se a categoria já existe nos contadores
            const index = categoriesCounts.findIndex(item => item.categoryName === dataCategory.description);
            if (index !== -1) {
              // Incrementar a contagem se a categoria já existe
              categoriesCounts[index].categoryCount++;
            } else {
              // Adicionar a categoria se não existir nos contadores
              categoriesCounts.push({ categoryName: dataCategory.description, categoryCount: 1 });
            }
          });
        });

        setCategoriesNameQtd(categoriesCounts);
        console.log('Info categorias: ', categoriesCounts);

        setCarregando(false);

      })
      .catch((error) => {
        console.error('Erro ao obter dados dos clientes:', error);
        setCarregando(false);
      });


    // OBTÉM DADOS DAS TRANSAÇÕES
    const periodoTransações = new Date().toISOString().slice(0, 7);
    fetchTransactions(periodoTransações, periodoTransações);

    // OBTÉM QTDD DE SERVIÇOS POR CLIENTES
    api
      .get(`customer/countServices`)
      .then((response) => {

        const countServices = response.data;

        //console.log('transactions: ', transactions);
        setQtdServiçoPorCliente(countServices);
      })
      .catch((error) => {
        console.error('Erro ao obter quantidade de serviços por cliente:', error);
        //setCarregando(false);
      });


    // OBTÉM QTD DE CADASTROS E EXCLUSÕES DE CLIENTES POR PERÍODO
    api
      .post(`customer/countCreateAndDeletedCustomers`, { period: 'monthly' })
      .then((response) => {

        const qtdCadastrosEExclusões = response.data;

        //console.log('transactions: ', transactions);
        setQtdCadastrosEExclusõesClientes(qtdCadastrosEExclusões);
      })
      .catch((error) => {
        console.error('Erro ao obter quantidade de clientes cadastrados e deletados:', error);
        //setCarregando(false);
      });

  }


  const fetchTransactions = (startDate, endDate) => {

    //console.log('Periodo transações: ', date);

    // OBTÉM DADOS DAS TRANSAÇÕES
    api
      .get(`transactions/byDate?filterDate=${startDate} ${endDate}`)
      .then((response) => {

        const transactions = response.data;

        //console.log('transactions: ', transactions);
        setTableTransactions(transactions);
      })
      .catch((error) => {
        console.error('Erro ao obter dados das transações:', error);
        setCarregando(false);
      });
  }


  const fetchTransactionsBalance = (startDate, endDate) => {

    //console.log('Periodo transações: ', date);

    let profit = 0;
    let entry = 0;
    let expense = 0;


    // OBTÉM DADOS DAS TRANSAÇÕES
    api
      .get(`transactions/byDate?filterDate=${startDate} ${endDate}`)
      .then((response) => {

        const transactions = response.data;

        /*console.log('startDate(balanço): ', startDate);
        console.log('endDate(balanço): ', endDate);
        console.log('transactions(balanço): ', transactions);*/

        entry = transactions.reduce((total, transaction) => {
          if (transaction.type === 'entrada') {
            return total + transaction.amount;
          }
          return total;
        }, 0);

        expense = transactions.reduce((total, transaction) => {
          if (transaction.type === 'saída') {
            return total + transaction.amount;
          }
          return total;
        }, 0);

        profit = entry - expense;

        /*console.log('entry: ', entry);
        console.log('expense: ', expense);
        console.log('profit: ', profit);*/

        setMoneyData({
          profit: `R$ ${profit.toFixed(2)}`,
          entry: `R$ ${entry.toFixed(2)}`,
          expense: `R$ ${expense.toFixed(2)}`,
        });

        //setTableTransactions(transactions);
      })
      .catch((error) => {
        console.error('Erro ao obter dados das transações:', error);
        setCarregando(false);
      });
  }


  const obterMesesAnteriores = async () => {
    const hoje = new Date();
    const mesesAnteriores = [];

    for (let i = 0; i < 13; i++) {
      let anoAtual = hoje.getFullYear();
      let mesAtual = hoje.getMonth() - i + 1; // Adiciona 1 para compensar a indexação de 0 a 11

      if (mesAtual < 1) {
        mesAtual += 12; // Adiciona 12 para ajustar para o ano anterior
        anoAtual -= 1; // Subtrai 1 do ano atual ao retroceder para o ano anterior
      }

      let mesString = mesAtual.toString().padStart(2, '0'); // Formata para dois dígitos, adicionando zero à esquerda se necessário

      const mes = obterNomeMes(hoje.getMonth() - i);

      // OBTÉM DADOS DAS TRANSAÇÕES
      const date = `${anoAtual}-${mesString}`;
      //console.log('date: ', date);

      // Envolve a chamada api.get em uma promessa
      const promise = new Promise((resolve, reject) => {
        api.get(`transactions/byDate?filterDate=${date} ${date}`)
          .then(response => resolve(response.data))
          .catch(error => reject(error));
      });

      // Aguarda a resolução da promessa e processa os dados
      try {
        const transactions = await promise;

        const entry = transactions.reduce((total, transaction) => {
          return transaction.type === 'entrada' ? total + transaction.amount : total;
        }, 0);

        const expense = transactions.reduce((total, transaction) => {
          return transaction.type === 'saída' ? total + transaction.amount : total;
        }, 0);

        const profit = entry - expense;

        mesesAnteriores.unshift({
          mes,
          ano: anoAtual,
          profit: profit.toFixed(2),
          entry: entry.toFixed(2),
          expense: expense.toFixed(2),
        });
      } catch (error) {
        console.error('Erro ao obter dados das transações:', error);
      }
    }

    //console.log('balançoMesesAnteriores(DentroFunc): ', mesesAnteriores);
    return mesesAnteriores;
  }

  const obterNomeMes = (mes) => {
    const meses = [
      'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
      'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'
    ];
    return meses[(mes + 12) % 12];
  }


  const fetchCategories = () => {

    api
      .get('category/')
      .then((response) => {
        const item = response.data;

        //console.log('item: ', item);
        console.log('item.categorias: ', item.categories);

        const listCategories = item.categories

        // listCustomers, setListCustomers
        // categoriesNameQtd, setCategoriesNameQtd

        let categoriesCounts = [];

        // VERIFICA SE A LISTA DE CLIENTES JÁ FOI CARREGADA
        if (listCustomers.length == 0) return;

        // Iterar sobre cada cliente        
        listCustomers.forEach(customer => {
          // Iterar sobre cada categoria do cliente
          customer.category.forEach(categoryName => {
            // Verificar se a categoria já existe nos contadores
            const index = categoriesCounts.findIndex(item => item.categoryName === categoryName);
            if (index !== -1) {
              // Incrementar a contagem se a categoria já existe
              categoriesCounts[index].categoryCount++;
            } else {
              // Adicionar a categoria se não existir nos contadores
              categoriesCounts.push({ categoryName, categoryCount: 1 });
            }
          });
        });

        setCategoriesNameQtd(categoriesCounts);
        console.log('Info categorias: ', categoriesCounts);

        //setConfigMessages(result);
        //setTableData(result.categories);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });

  };


  useEffect(() => {

    fetchCustomers();

    // OBTEM BALAÇO DOS MESES ANTERIORES
    (async () => {
      setBalançoMesesAnteriores(await obterMesesAnteriores());
      console.log('balançoMesesAnteriores: ', balançoMesesAnteriores);
    })();

  }, []);










  // =======================================================================================================================


  return (
    <div>

      {/* Card widget */}
      <div className='text-center mt-10 text-xl _flex dark:text-white'>
        <MdSupervisedUserCircle className="h-7 w-7 mx-auto" />
        <h1>
          Clientes no Sistema
        </h1>
      </div>

      <div className="mt-3 grid grid-cols-1 gap-5  md:grid-cols-4">
        <Widget
          icon={<MdPerson className="h-7 w-7" />}
          title={'Clientes Total'}
          subtitle={clientsData.total}
          carregando={carregando}
        />
        <Widget
          icon={<BsFillPersonCheckFill className="h-6 w-6" />}
          title={'Clientes Dentro da Validade'}
          subtitle={clientsData.active}
          carregando={carregando}
        />
        <Widget
          icon={<BsFillPersonXFill className="h-6 w-6" />}
          title={'Clientes Vencidos'}
          subtitle={clientsData.expired}
          carregando={carregando}
        />
        <Widget
          icon={<MdOutlinePersonOff className="h-7 w-7" />}
          title={'Clientes Desativados'}
          subtitle={clientsData.disabled}
          carregando={carregando}
        />
      </div>


      {/* Card widget */}
      <div className="mt-20 text-xl _flex text-center dark:text-white">
        <MdListAlt className="h-7 w-7 mx-auto" />
        <h1>
          Total Financeiro de Clientes
        </h1>
      </div>

      <div className="mt-3 grid grid-cols-1 gap-5  md:grid-cols-4">
        <Widget2
          title={'Filtro'}
          fetchTransactionsBalance={fetchTransactionsBalance}
        />
        <Widget
          icon={<MdAttachMoney className="h-7 w-7" />}
          title={'Lucros'}
          subtitle={moneyData.profit}
          carregando={carregando}
        />
        <Widget
          icon={<MdArrowCircleUp className="h-6 w-6" />}
          title={'Entrada'}
          subtitle={moneyData.entry}
          carregando={carregando}
        />
        <Widget
          icon={<MdMoneyOff className="h-7 w-7" />}
          title={'Despesas'}
          subtitle={moneyData.expense}
          carregando={carregando}
        />
      </div>

      {/* =======================       GRAFICO - BALANÇO MENSAL        ======================= */}
      <br />
      <br />

      <div className='grid gap-4 grid-rows-1 _h-[350px]  md:gap-4 md:grid-cols-2'>{/* flex h-[350px] */}

        {/* ------------------------------------------------------------------------------------------------ */}
        <div className='_w-[50%] h-[350px] dark:text-white'>
          {/*console.log('Criando 1º grafico')*/}

          <AreaChart
            options={{
              chart: {
                //type: 'area',
                //height: '100%',
                width: '50%',
                zoom: {
                  enabled: true
                },
                foreColor: '#979797',
              },

              xaxis: {
                categories: balançoMesesAnteriores.map((dadosBalanço) => {
                  return dadosBalanço.mes;
                }) /*['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set']*/
              },

              // ------------------------------------------------------------------------------
              /*dataLabels: {
                  enabled: false
              },*/
              stroke: {
                curve: 'straight'
              },

              title: {
                text: 'Balanço - anual',
                align: 'left'
              },
              subtitle: {
                text: 'Valores',
                align: 'left'
              },
              //labels: series.monthDataSeries1.dates,
              /*xaxis: {
                  type: 'datetime',
              },*/
              yaxis: {
                opposite: true
              },
              legend: {
                horizontalAlign: 'left'
              },

              /*theme: {
                  mode: 'dark',
              },*/
            }}

            series={
              [{
                name: "VALOR",
                data: balançoMesesAnteriores.map((dadosBalanço) => {
                  return dadosBalanço.profit;
                })//[30, 40, 35, 50, 49, 60, 70, 91, 125]
              }]
            }
          />

        </div>

        {/* ------------------------------------------------------------------------------------------------ */}

        <div className='_w-[50%] h-[350px]'>

          <DonutChart
            options={{
              chart: {
                type: 'donut', //pie //donut
                //height: '100%',
                //width: '100%',
                foreColor: '#979797',
              },
              colors: [
                "#FF7F50", // Coral
                "#32CD32", // Amarelo Limão
                "#87CEEB", // Azul Céu
                "#FFDAB9", // Pêssego
                "#E6E6FA", // Lavanda
                "#D2691E", // Marrom Chocolate
                "#808000", // Verde Oliva
                "#40E0D0", // Turquesa
                "#FA8072", // Salmão
                "#00C957", // Verde Esmeralda
                "#1E90FF", // Azul Dodger
                "#FFB6C1", // Rosa Claro
                "#00FF00", // Verde Lima
                "#00FFFF", // Ciano
                "#800080", // Roxo
                "#F4A460", // Areia
                "#7FFFD4", // Verde Água
                "#FFD700", // Ouro
                "#DA70D6", // Orquídea
                "#4B0082", // Índigo
                "#CD5C5C", // Vermelho Indiano
                "#D3D3D3", // Cinza Claro
                "#48D1CC", // Turquesa Média
                "#FF6347", // Tomate
                "#556B2F", // Oliva Escura
                "#C0C0C0", // Prata
                "#ADD8E6", // Azul Pálido
                "#EE82EE", // Violeta
                "#191970", // Azul Meia-Noite
                "#F5F5DC"  // Bege
              ],

              labels: /*['Qualcon', 'Blackout', 'Sua tv', '4Fun', 'p2cine'],*/ categoriesNameQtd.map((dataCategory) => { return dataCategory.categoryName }),
              /*responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
              }],*/
            }}
            series={/*[44, 55, 41, 17, 15]*/ categoriesNameQtd.map((dataCategory) => { return dataCategory.categoryCount })}
          />

        </div>





        {/* ------------------------------------------------------------------------------------------------ */}
        <div className='_w-[50%] h-[350px] dark:text-white'>
          {/*console.log('Criando 1º grafico')*/}

          <BarChart
            options={{
              chart: {
                width: '50%',
                zoom: {
                  enabled: false
                },
                foreColor: '#979797',
              },
              xaxis: {
                categories: qtdServiçoPorCliente.map((dadosQtdServiço) => {
                  return dadosQtdServiço.name;
                })//["QUALCON", "BLACKOUT", "SUA TV"]
              },
              stroke: {
                curve: 'straight'
              },
              title: {
                text: 'Serviços por clientes',
                align: 'left'
              },
              subtitle: {
                text: 'Quantidade',
                align: 'left'
              },
              yaxis: {
                opposite: true
              },
              legend: {
                horizontalAlign: 'left'
              },
            }}
            series={[{
              name: "Clientes",
              data: qtdServiçoPorCliente.map((dadosQtdServiço) => { //qtdCadastrosEExclusõesClientes
                return dadosQtdServiço.amount;
              })
            }]}
          />

        </div>



        {/* ------------------------------------------------------------------------------------------------ */}
        <div className='_w-[50%] h-[350px] dark:text-white'>
          <LineChart
            options={{
              chart: {
                type: 'line',
                zoom: {
                  enabled: false
                },
                foreColor: '#979797',
              },
              xaxis: {
                categories: qtdCadastrosEExclusõesClientes.map((dados) => {
                  return dados.dateOrMonth;
                }), // Categorias do eixo X
              },
              colors: ['#1E90FF', '#FF7F50'],
            }}
            series={[
              {
                name: "Clientes adicionados",
                data: qtdCadastrosEExclusõesClientes.map((dados) => {
                  return dados.registeredCount;
                })
              },
              {
                name: "Clientes removidos",
                data: qtdCadastrosEExclusõesClientes.map((dados) => {
                  return dados.deletedCount;
                })
              }
            ]}
          />
        </div>

      </div>




      {/* -------------------------------- TABELA - RELATÓRIO -------------------------------- */}
      <div className='mt-10'>
        <TableReport
          columnsData={columnsReport}
          tableData={tableTransactions}
          cabeçalo='Registro financeiro'
          fetchCustomers={fetchCustomers}
          fetchTransactions={fetchTransactions}
        />
      </div>




      {/* =======================       TABELAS - CLIENTES VENCENDO E VENCIDOS        ======================= */}

      {/* TITULO */}
      <div className="mt-0 md:mt-20 text-xl text-center hidden dark:text-white">
        <MdCalendarToday className="h-7 w-7 mx-auto" />
        <h1>
          Vencimento Clientes
        </h1>
      </div>

      <div className='grid gap-4 grid-rows-1 hidden  md:gap-4 md:grid-cols-2'> {/* inline-flex */}

        {/* TABELA - CLIENTES PERTO DE VENCER */}
        <div className=''>
          <CheckTableVencendo
            columnsData={columnsDataServices}
            tableData={tableData}
            cabeçalo='Clientes perto de vencer (5 ou menos dias)'
            fetchCustomers={fetchCustomers}
          />
        </div>

        {/* TABELA - CLIENTES VENCIDOS */}
        <div className=''>
          <CheckTableExpirado
            columnsData={colunasTabVencidos}
            tableData={clientesVencidos}
            cabeçalo='Clientes vencidos (ativados)'
          />

        </div>

      </div>

    </div>
  );
};

export default Dashboard;
