import {
  
  Accordion,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  AccordionItem,

  /*Heading, Icon, Stack,
  AccordionItemContent,
  AccordionItemTrigger,
  AccordionRoot,*/
} from '@chakra-ui/react';

import { LuBarChart, LuTags } from "react-icons/lu"





function MessageTriggers() {

  const items = [
    {
      value: "info",
      icon: <LuTags />,
      title: "Gatilhos de menssagem",
      content:
        '{NOME} : Nome completo' +
        <br /> +
        '{PRIMEIRO_NOME} : Primeiro Nome' +
        <br /> +
        '{ZAP} : Whatsapp' +
        <br /> +
        '{LOGIN} : Login' +
        <br /> +
        '{SENHA} : Senha' +
        <br /> +
        '{PLANO} : Plano' +
        <br /> +
        '{PRODUTO} : Produto/Mensal' +
        <br /> +
        '{OBS} : Observações' +
        <br /> +
        '{PAGAMENTO} : Fatura' +
        <br /> +
        '{DATA_VENCI} : Data de Vencimento' +
        <br />,
    },
    {
      value: "stats",
      icon: <LuBarChart />,
      title: "Stats",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur nec odio vel dui euismod fermentum.",
    },
  ]


  // ==========================================================================================================================
  return (
    <>

    <Accordion className="w-full" allowMultiple>
      <AccordionItem className="border-b border-gray-200 py-[17px] dark:!border-white/10">

        <h2>
          <AccordionButton className="flex justify-between button_default">
            <span
              className="text-sm text-left font-bold text-navy-900 dark:text-white"
              flex="1"
              //textAlign="left"
            >
              Gatilhos de menssagem
            </span>
            <AccordionIcon className="text-left !text-navy-900 dark:!text-white" />
          </AccordionButton>
        </h2>

        <AccordionPanel
          className="text-left text-teal-800 text-medium mt-2  dark:!text-white"
          pb={4}
        >
          {'{NOME} : Nome completo'}
          <br />
          {'{PRIMEIRO_NOME} : Primeiro Nome'}
          <br />
          {'{ZAP} : Whatsapp'}
          <br />
          {'{LOGIN} : Login'}
          <br />
          {'{SENHA} : Senha'}
          <br />
          {'{PLANO} : Plano'}
          <br />
          {'{PRODUTO} : Produto/Mensal'}
          <br />
          {'{OBS} : Observações'}
          <br />
          {'{PAGAMENTO} : Fatura'}
          <br />
          {'{DATA_VENCI} : Data de Vencimento'}
          <br />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>

    {/* ------------------------------------------------------------------------------------------------------------*/}
    {/*}<Stack width="full">
      <Heading size="md">Product details</Heading>
      <AccordionRoot collapsible defaultValue={["info"]}>
        {items.map((item) => (
          <AccordionItem key={item.value} value={item.value}>
            <AccordionItemTrigger>
              <Icon fontSize="lg" color="fg.subtle">
                {item.icon}
              </Icon>
              {item.title}
            </AccordionItemTrigger>
            <AccordionItemContent>{item.content}</AccordionItemContent>
          </AccordionItem>
        ))}
      </AccordionRoot>
    </Stack>{*/}

    </>

  );
}

export default MessageTriggers;
