import { useEffect, useState } from 'react';
import api from 'api/api';
import Card from 'components/card';
import {
  /*MdAdd,
  MdDelete,
  MdEdit,
  MdMessage,
  MdOutlineRepeat,
  MdSearch,  
  MdPowerSettingsNew,*/
  MdCached,
} from 'react-icons/md';
import { loadMercadoPago } from "@mercadopago/sdk-js";
import { initMercadoPago, CardPayment, Payment, StatusScreen } from '@mercadopago/sdk-react';
//import { MercadoPagoConfig, Payment } from 'mercadopago';
//const mercadopago = require("mercadopago");


/** Teste produto 3
   * Public_Key: APP_USR-d65c6c0d-0656-49e1-bfd7-763c63485277
   * Access_Token: APP_USR-2331832716267556-101801-6883b9c8a1dc25a39655dd2738a96bfe-1309493511
   */

/** TRANS-BRICKS 
 * (Credenciais teste)
 * Public_Key: TEST-58c6e6e1-1d7e-4e43-932d-47369201a20d
 * Access_Token: TEST-5353579793617464-101905-0b14be06abef136fb3047e0d0c727606-82946526
 * 
 * (Credenciais produção)
 * Public_Key: APP_USR-29c225c8-5c24-4813-a27f-9b80c0e0eb24
 * Access_Token: APP_USR-5353579793617464-101905-29c4817070ae181edc971e8a3850afcf-82946526
 */

const Public_Key = 'TEST-58c6e6e1-1d7e-4e43-932d-47369201a20d';
//const Access_Token = 'TEST-5353579793617464-101905-0b14be06abef136fb3047e0d0c727606-82946526';

initMercadoPago(Public_Key, { locale: 'pt-BR' });



// ===========================================================================================================================
const MercadoPagoComponent = () => {

  const [key, setKey] = useState(0);
  const [key2, setKey2] = useState(1);
  const [paymentId_SS, setPaymentId_SS] = useState('90861816881'); //90861816881
  const [visivel_SS, setVisivel_SS] = useState(false);
  const [visivel_Bricks, setVisivel_Bricks] = useState(true);

  const initialization = {
    amount: 1,
    payer: {
      email: 'jonatas.santos.ex@gmail.com',
      identification: {
        type: '12345678909',
        number: 'CPF',
      },
    },
  };

  const customization = {
    visual: {
      style: {
        theme: 'dark',
        customVariables: {
          //theme: 'dark', // | 'dark' | 'bootstrap' | 'flat'
          //formBackgroundColor: '#1d2431',
          //baseColor: 'aquamarine'
        }
      }
    },
    paymentMethods: {
      maxInstallments: 1,
      bankTransfer: ['pix'],
      creditCard: 'all',
      //atm: 'all',
    }
  }

  const initializationStatusScreen = {
    paymentId: paymentId_SS,
  };


  const onSubmit = async (formData) => {
    // callback chamado ao clicar no botão de submissão dos dados
    return new Promise((resolve, reject) => {

      console.log('formData:', formData);

      api
        .post(`mercadopago/process_payment`, formData.formData)

        //.then((response) => response.json())
        .then((response) => {
          // receber o resultado do pagamento
          console.log('Processo de pagamento executado:', response);
          setPaymentId_SS(response.data.result.id);
          setVisivel_Bricks(false);
          setVisivel_SS(true);          
          resolve();
        })
        .catch((error) => {
          // lidar com a resposta de erro ao tentar criar o pagamento
          console.log('Erro no pagamento:', error);
          alert('Ocorreu um erro, tente novamente...');
          reject();
        });
    });
  };

  const onError = async (error) => {
    // callback chamado para todos os casos de erro do Brick
    alert('Ocorreu um erro, tente novamente...');
    console.log('Erro no Bricks', error);
  };

  const onReady = async () => {
    /*
        Callback chamado quando o Brick estiver pronto.
        Aqui você pode ocultar loadings do seu site, por exemplo.
      */
  };




  // ============================================================================================================================
  useEffect(() => {

  }, []);

  return (

    <>
      <header className="relative flex items-center py-4">
        <button
          onClick={
            () => {
              setVisivel_SS(false);
              setVisivel_Bricks(true);
              setKey(prevKey => prevKey + 1);
              setKey2(prevKey => prevKey + 1);
            }
          }
          className="flex items-center justify-center rounded-full bg-brand-500 p-2 ml-3 text-3xl text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-cyan-600 dark:text-white dark:hover:bg-cyan-700 dark:active:bg-cyan-600 rotacionar_ampliar"
          title='Atualizar lista'
        >
          <MdCached />
        </button>
      </header>

      <div
        className={`${visivel_Bricks ? '' : 'hidden'}`}
      >
        <Payment
          key={key}
          initialization={initialization}
          customization={customization}
          onSubmit={onSubmit}
          onReady={onReady}
          onError={onError}
        />
      </div>

      <div
        className={`${visivel_SS ? '' : 'hidden'}`}
      >
        <StatusScreen
          key={key2}
          initialization={initializationStatusScreen}
          customization={{
            visual: {
              hideStatusDetails: false,
              hideTransactionDate: false
            }
          }}
        />
      </div>
    </>

  );
};

export default MercadoPagoComponent;
