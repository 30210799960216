import { useEffect, useState, useRef } from 'react';
import Widget from './components/Widget';
import { IoMdHome } from 'react-icons/io';
import { MdBarChart, MdDashboard, MdMessage, MdSearch, MdAdd, MdInsertEmoticon } from 'react-icons/md';
import api from 'api/api';
import InputField from './components/InputField';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { Fade/*, ScaleFade, Slide, SlideFade, Collapse*/ } from '@chakra-ui/react'
import { useDisclosure } from '@chakra-ui/hooks';
import Card from 'components/card';

import TextField from './components/TextField';
import Toast from 'components/toast';
import MessageTriggers from './components/MessageTriggers';

const MessagesConfiguration = () => {


  const [configMessages, setConfigMessages] = useState({ tasks: [] });

  const handleUpdateConfigMessages = (data) => {

    const result = {
      //_id: item._id,
      tasks: data.tasks, //item.msgs,
    };

    setConfigMessages(result);
  };

  // ----------------------------------------------------------------------------------------------------------------------

  // PARA CONTROLE DO MODAL
  const { isOpen, onOpen, onClose } = useDisclosure();

  const abrirModal = (data) => {
    console.log('Entrou na Func abrirModal');
    console.log('data: ', data);
    //console.log('editMsg: ', editMsg);

    setModalData(data);
    onOpen(true);

    // SE FOR UMA MSG DO TIPO MEDIA, ABRE O CAMPO DO LINK
    if (data?.dataMsg?.type === 'image' || data?.dataMsg?.type === 'video') {

      setTimeout(() => {

        document.querySelector('#pai-link-mulmidia').classList.remove('pb-0');
        document.querySelector('#pai-link-mulmidia').classList.add('pb-[96px]');
      }, 500);
    }
  };


  // Define o estado para armazenar conteúdo do titulo
  const [titulo, setTitulo] = useState('');

  const [mediaType, setMediaType] = useState('text');
  const [linkArq, setLinkArq] = useState('');

  const [editMsg, setEditMsg] = useState(false);

  const [modalData, setModalData] = useState({
    //id_msg: '',
    taskName: '',
    //desc: '',
    intervalType: 'days',
    intervalValue: 1,
    frequency: 1,
    executionTime: '',
    categorySend: 'addCategoria',
    status: true,
    dataMsg: {
      type: mediaType,
      media: linkArq,
      msg: '',
    },
    selectValue: 'text',
    recorrenciaType: 'recorrente',
    diaDisparo: 1,
    addMsgPix: false,
    //selectTypeInterval: 'dias', //dias
  });

  const [isLoading, setIsLoading] = useState(false);

  // Define o conteúdo para o titulo da textarea
  const [tituloCampoTxtArea, setTituloCampoTxtArea] = useState('Mensagem');

  // Define a referência para o textarea
  const textFieldRef = useRef(); // Referência para o TextField

  // Define o estado para controlar se o seletor de emoticons está aberto ou não
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);

  // Define o estado para armazenar a mensagem atual no textarea
  const [message, setMessage] = useState('');

  // Define o estado para armazenar a opção de categoria p/ envio
  const [categorySendState, setCategorySendState] = useState('addCategoria');

  // Define o estado para armazenar o intervalo
  const [intervalValueState, setIntervalValueState] = useState(1);

  // Define o estado para armazenar o tipo de intervalo
  const [intervalTypeState, setIntervalTypeState] = useState('days');

  // Define o estado para armazenar o tipo de recorrencia
  const [recorrenciaTypeState, setRecorrenciaTypeState] = useState('recorrente');

  // Define o estado para armazenar a hora de disparo
  const [executionTimeState, setExecutionTimeState] = useState('');

  // Lista de categorias
  const [categorias, setCategorias] = useState([]);

  // Lista de Serviços
  const [customerServiceOptions, setCustomerServiceOptions] = useState([]);

  // Estado para dia de disparo
  const [diaDisparo, setDiaDisparo] = useState(1);

  // Define estado para gerir o envio de msg com Pix
  const [toggleAddMsgPix, setToggleAddMsgPix] = useState(false);


  // Define o estado para controlar a classe do seletor de emoticons
  const [emojiPickerClass, setEmojiPickerClass] = useState('hidden');

  // Define a função para alternar a visibilidade do seletor de emoticons
  const toggleEmojiPicker = () => {
    //console.log('Dados conj-emoticons: ', document.querySelector('#conj-emoticons').classList.contains('display') );
    //const conj_emoticons_display = document.querySelector('#conj-emoticons') ? document.querySelector('#conj-emoticons').classList.contains('display') : false;
    console.log('Display conj-emoticons: ', document.querySelector('#conj-emoticons')?.classList.contains('aparecer-pra-baixo'));
    if (document.querySelector('#conj-emoticons')?.classList.contains('aparecer-pra-baixo')) { //isEmojiPickerOpen
      setEmojiPickerClass('');
      /*setTimeout(() => {
        setIsEmojiPickerOpen(false);
      }, 1_000);*/ // Ajuste este valor para corresponder à duração da transição CSS
    } else {
      setIsEmojiPickerOpen(true);

      const element = document.querySelector('#conj-emoticons');
      element.style['margin-top'] = '-40%';
      element.style['display'] = '';
      //const marginCustom = Number('-' + $(seletor).css('height').replace('px', '')) - 20 + 'px';
      //element.style['margin-top'] = marginCustom;

      setEmojiPickerClass('aparecer-pra-baixo');
    }
  };

  // Define a função para lidar com o clique em um emoticon
  // Adiciona o emoticon clicado na posição do cursor no textarea e fecha o seletor de emoticons
  const handleEmojiClick = (emoji) => {
    if (textFieldRef.current) {
      const { selectionStart, value } = textFieldRef.current; //.querySelector('textarea'); //textareaRef.current;
      setMessage(value.slice(0, selectionStart) + emoji + value.slice(selectionStart));
      //setIsEmojiPickerOpen(false);
    }
  };

  // Define a função para lidar com a mudança no textarea
  // Atualiza a mensagem com o valor atual do textarea e mantém o foco no textarea
  const handleTextChange = (event) => {
    setMessage(event.target.value);
    //textareaRef.current.focus();
  };

  // DEFINE O TIPO DE MENSAGEM DE ACORDO COM A OPÇÃO ESCOLHIDA NO SELECT TIPO MSG
  const handleSelectTipoMsgChange = (e) => {

    const tipoMsg = e.target.value;
    console.log('Select tipo msg: ', tipoMsg);

    if (tipoMsg === 'text') {

      // DEFINE O TITULO DO CAMPO 'TEXTAREA'
      setTituloCampoTxtArea('MENSAGEM');

      // RESETA OS DADOS P/ MSG DO MEDIA
      setMediaType('text');
      setLinkArq('');

      setModalData((prevState) => ({
        ...prevState,
        dataMsg: {
          type: tipoMsg,
          media: modalData?.dataMsg?.media || '',
          msg: modalData?.dataMsg?.msg || '',
        },
      }));

      document.querySelector('#pai-link-mulmidia').classList.add('pb-0');
      document.querySelector('#pai-link-mulmidia').classList.remove('pb-[96px]');
    }
    else if (tipoMsg === 'image' || tipoMsg === 'video') {

      // DEFINE O TITULO DO CAMPO 'TEXTAREA'
      setTituloCampoTxtArea('LEGENDA');

      // DEFINE OS DADOS P/ MSG DO MEDIA
      setModalData((prevState) => ({
        ...prevState,
        dataMsg: {
          type: tipoMsg,
          media: modalData?.dataMsg?.media || '',
          msg: modalData?.dataMsg?.msg || '',
        },
      }));

      setMediaType(tipoMsg);
      //setLinkArq(false);

      //document.querySelector('#linkMulmidia').classList.remove('hidden');

      const element = document.querySelector('#linkMulmidia');
      /*element.style['margin-top'] = '-40%';
      element.style['display'] = '';*/

      document.querySelector('#pai-link-mulmidia').classList.remove('pb-0');
      document.querySelector('#pai-link-mulmidia').classList.add('pb-[96px]');
      //element.classList.add('aparecer-pra-baixo');

      //element.classList.add('mt-0');
      //element.classList.remove('mt-[-40%]');
    }
  };

  // DEFINE O TIPO DE INTERVALO DE ACORDO COM A OPÇÃO ESCOLHIDA NO SELECT TIPO DE INTERVALO
  const handleSelectTipoIntervaloChange = (e) => {

    const tipoIntervalo = e.target.value;
    console.log('Select tipo intervalo: ', tipoIntervalo);

    // DEFINE OS DADOS DO TIPO DE INTERVALO
    setModalData((prevState) => ({
      ...prevState,
      intervalType: tipoIntervalo,
    }));

    setIntervalTypeState(tipoIntervalo);
  };// Fim: handleSelectTipoIntervaloChange

  // DEFINE O TIPO DE RECORRENCIA DE ACORDO COM A OPÇÃO ESCOLHIDA NO SELECT TIPO DE RECORRENCIA
  const handleSelectTipoRecorrencia = (e) => {

    const tipoRecorrencia = e.target.value;
    console.log('Select tipo recorrencia: ', tipoRecorrencia);

    // DEFINE OS DADOS DO TIPO DE RECORRENCIA
    setModalData((prevState) => ({
      ...prevState,
      recorrenciaType: tipoRecorrencia,
    }));

    setRecorrenciaTypeState(tipoRecorrencia);
  };// Fim: handleSelectTipoRecorrencia

  // DEFINE O TIPO DE CATEGORIA DE ENVIO DE ACORDO COM A OPÇÃO ESCOLHIDA NO SELECT TIPO DE CATEGORIA
  const handleSelectTipoCategoriaChange = (e) => {

    const tipoCategoria = e.target.value;
    console.log('Select tipo categoria: ', tipoCategoria);

    // DEFINE OS DADOS DO TIPO DE CATEGORIA
    setModalData((prevState) => ({
      ...prevState,
      categorySend: tipoCategoria,
    }));

    setCategorySendState(tipoCategoria);
  };// Fim: handleSelectTipoCategoriaChange

  // DEFINE O DIA DE DISPARO DE ACORDO COM A OPÇÃO ESCOLHIDA NO SELECT
  const handleSelectDiaDisparoChange = (e) => {

    const diaDisparo = e.target.value;
    console.log('Select dia disparo: ', diaDisparo);

    // DEFINE OS DADOS DO TIPO DE INTERVALO
    setModalData((prevState) => ({
      ...prevState,
      diaDisparo: diaDisparo,
    }));

    setDiaDisparo(diaDisparo);
  };// Fim: handleSelectTipoIntervaloChange



  const handleAddMsgPix = () => {
    const value_toggleAddMsgPix = toggleAddMsgPix ? false : true;
    setToggleAddMsgPix(value_toggleAddMsgPix);
    setModalData((prevState) => ({
      ...prevState,
      addMsgPix: value_toggleAddMsgPix,
    }));
  };


  const tipoMsgSelect = useRef();
  const tipoIntervaloSelect = useRef();
  const tipoCategoriaSelect = useRef();
  const tipoRecorrenciaSelect = useRef();
  const diaDisparoSelect = useRef();






  // =============================================================================================================================
  const editConfigMessages = (editMsg) => {
    setIsLoading(true);

    let body = configMessages;

    // VERIFICA SE É UMA EDIÇÃO OU ADIÇÃO DE UMA MSG
    console.log('body(criar agendamento): ', body);

    if (editMsg !== false) {
      body.tasks[editMsg] = modalData;
      setEditMsg(false);
    }
    else body.tasks.push(modalData);

    console.log('body: ', body);
    api
      .post('schedule/create-tasks/' /*+ body._id*/, body)
      .then((response) => {
        Toast.fire({
          icon: 'success',
          title: 'Sua mensagem foi Agendada com sucesso!',
        });
        onClose();
        //setTimeout(() => {
        console.log('Dados retornado: ', response.data);
        handleUpdateConfigMessages(response.data);
        //}, 1000);

        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });
  };// Fim Func editConfigMessages


  // PARA CONTROLE DO CAMPO DE PESQUISA
  // 1. Crie um novo estado para armazenar o valor do campo de pesquisa
  const [searchTerm, setSearchTerm] = useState('');

  // 2. Atualize esse estado sempre que o valor do campo de pesquisa mudar
  const handleSearchChange = (event) => {
    //console.log('Dados da pesquisa: ', event.target.value);
    setSearchTerm(event.target.value);
  };

  // ----------------------------------------------------------------------------------------------------------------------

  useEffect(() => {

    // OBTEM OS DADOS DE "AGENDAMENTO"
    api
      .get('schedule/get-all-tasks')
      .then((response) => {
        const item = response.data;

        //console.log('item.msgs: ', item.msgs);
        console.log('item.tasks: ', item.tasks);

        const result = {
          //_id: item._id,
          tasks: item.tasks, //item.msgs,
        };

        setConfigMessages(result);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });


    // OBTEM OS DADOS DAS "CATEGORIAS"
    api
      .get('category')
      .then((response) => {
        const result = response.data.categories.map((item) => {
          return { label: item.label, description: item.description };
        });
        setCategorias(result);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });

    // OBTEM OS DADOS DE "SERVIÇOS"
    api
      .get('service')
      .then((response) => {
        const result = response.data.services.map((item) => {
          return { name: item.name, id: item._id };
        });
        setCustomerServiceOptions(result);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });


  }, []);

  useEffect(() => {
    console.log('modalData.intervalValue: ', modalData.intervalValue);
  }, [modalData.intervalValue]);



  const filteredWidgets = configMessages.tasks?.filter((dadosMsgAgendada) => {
    //console.log('O titulo possui dados da pesquisa: ', dadosMsgAgendada.title.toLowerCase().includes(searchTerm.toLowerCase()));

    if (searchTerm === '') return dadosMsgAgendada;
    else if (dadosMsgAgendada.taskName.toLowerCase().includes(searchTerm.toLowerCase())) return dadosMsgAgendada;
    else if (dadosMsgAgendada.desc.toLowerCase().includes(searchTerm.toLowerCase())) return dadosMsgAgendada;
    else return false;
  });


  return (
    <div
      style={{ width: 'auto', marginTop: 20 }}
      className="flex flex-col  justify-between space-y-4"
    >
      {/* ================================================== CABEÇALHO ================================================== */}
      <Card extra={'w-full h-full sm:overflow-auto px-6 mt-1'}>
        <header className="relative flex items-center py-4">

          {/* --------------------- BOTÃO ADD --------------------- */}
          <button
            onClick={() => {
              setEditMsg(false);
              onOpen(modalData);
              console.log('Btn add Agendamento clicado');
            }}
            className="flex items-center justify-center rounded-full bg-brand-500 p-2 text-3xl text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-300 dark:text-white dark:hover:bg-brand-400 dark:active:bg-brand-200 ampliar"
            title='Adicionar mensagem'
          >
            <MdAdd />
          </button>

          {/* --------------------- CAMPO PESQUISA --------------------- */}
          <div className="flex flex-row items-center space-x-2 ml-auto">
            <MdSearch />
            <InputField
              placeholder="Pesquisar..."
              id="campo-pesquisa"
              type="text"
              extra="w-full"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </header>
      </Card>



      {/* ==================================================== CONSTROI O MADAL ==================================================== */}
      {<Card extra="!flex-row flex-grow items-center rounded-[20px]">

        <Modal /*data={data}*/ isOpen={isOpen} onClose={onClose} className="!z-[1010]">
          <ModalOverlay className="bg-[#000] !opacity-30" />
          <ModalContent className="!z-[1002] !m-auto !w-max min-w-auto _!max-w-[85%] top-[12vh] sm:top-[12vh] sm:min-w-[700px]">
            <ModalBody className=''>
              <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[700px] flex !z-[1004] max-h-[450px] overflow-y-auto  md:max-h-[600px]">

                {/* ---------------------------------------- CAMPO TITULO ---------------------------------------- */}
                {/*<h1 className="mb-[20px] text-xl font-bold">{modalData.title}</h1>*/}
                <div className="my-[20px]">
                  <div>
                    <label
                      htmlFor={''}
                      className="ml-0 text-sm font-bold text-navy-700 dark:text-white"
                    >
                      TITULO
                    </label>
                  </div>

                  <InputField
                    placeholder="Mensagem p/ clientes ativos..."
                    id="customer.name"
                    type="text"
                    extra="w-full"
                    value={titulo}
                    onChange={(e) => {
                      setModalData((prevState) => ({
                        ...prevState,
                        taskName: e.target.value,
                      }));

                      setTitulo(e.target.value);
                    }}
                  />
                </div>

                {/* ---------------------------------------- CAMPO DESCRIÇÃO ---------------------------------------- */}
                {/*}
                <div className="my-[20px] mb-8">
                  <div>
                    <label
                      htmlFor={''}
                      className="ml-0 text-sm font-bold text-navy-700 dark:text-white"
                    >
                      DESCRIÇÃO
                    </label>
                  </div>

                  <InputField
                    placeholder="Mensagem promocional..."
                    id="customer.name"
                    type="text"
                    extra="w-full"
                    value={modalData.desc}
                    onChange={(e) => {
                      setModalData((prevState) => ({
                        ...prevState,
                        desc: e.target.value,
                      }));
                    }}
                  />
                </div>
                {*/}


                {/* ---------------------------------------- SELECT CATEGORIA ---------------------------------------- */}
                <div className="my-[20px] mb-8">

                  <div>
                    <label
                      htmlFor={''}
                      className="ml-0 text-sm font-bold text-navy-700 dark:text-white"
                    >
                      CATEGORIA DE ENVIO
                    </label>
                  </div>

                  {/* SELECTE DO TIPO DE CATEGORIA */}
                  <div className=''>
                    <select
                      onChange={handleSelectTipoCategoriaChange}
                      id="select-Tipo-Categoria"
                      class="mr-auto mt-2 flex _w-auto items-center justify-center rounded-xl border bg-white/0 p-1 text-sm outline-none w-full"
                      ref={tipoCategoriaSelect}
                      value={categorySendState}
                    >
                      <option className="dark:bg-gray-700" value="addCategoria">Selecione uma categoria...</option>
                      <option className="dark:bg-gray-700" value="clientesAtivos">Clientes ativos</option>
                      <option className="dark:bg-gray-700" value="clientesVenceHoje">Clientes vencendo hoje</option>
                      <option className="dark:bg-gray-700" value="clientesVencidos">Clientes vencidos</option>

                      {categorias.map((categoria, index) => (
                        <option className="dark:bg-gray-700" key={index} value={categoria.description}>{categoria.description}</option>
                      ))}

                      {customerServiceOptions.map((serviço, index) => (
                        <option className="dark:bg-gray-700" key={index} value={serviço.name}>{serviço.name}</option>
                      ))}

                    </select>
                  </div>

                </div>


                {/* -------------------------------- LINHA DE DIVISÃO -------------------------------- */}
                <hr className="border-gray-200 dark:!border-white/20 mb-4 mt-5" />

                {/* -------------------------------- CAMPO LINK DO ARQ MULTIMIDIA -------------------------------- */}
                <div id='pai-link-mulmidia' className='overflow-hidden transition-all duration-1000 pb-0'>
                  <div id='linkMulmidia' className="_aparecer-pra-baixo-default transition-all duration-1000" style={{ /*display: 'none',*/ }} >
                    <div className='text-center'>
                      <label
                        htmlFor={''}
                        className="ml-0 text-sm font-bold text-navy-700 dark:text-white"
                      >
                        LINK ARQUIVO MULTIMIDIA
                      </label>
                    </div>

                    <InputField
                      placeholder="http://imagem.jpg ..."
                      id="inputLinkMulmidia"
                      type="text"
                      extra="w-full"
                      value={linkArq}
                      onChange={(e) => {
                        setModalData((prevState) => ({
                          ...prevState,
                          dataMsg: {
                            type: modalData.dataMsg.type,
                            media: e.target.value,
                            msg: modalData.dataMsg.msg,
                          },
                        }));

                        setLinkArq(e.target.value);
                      }}
                    />
                  </div>
                </div>

                {/* ----------------------------------- CAMPO 'TEXTAREA' DA MSG ----------------------------------- */}
                <div id="contentMsg" className="">
                  <TextField
                    label={tituloCampoTxtArea}
                    textFieldRef={textFieldRef}
                    value={message}
                    onChange={(e) => {
                      setModalData((prevState) => ({
                        ...prevState,
                        //msg: e.target.value,
                        dataMsg: {
                          type: modalData?.dataMsg?.type,
                          media: modalData?.dataMsg?.media || '',
                          msg: e.target.value,
                        },
                      }));
                      handleTextChange(e);
                    }
                    }
                  />

                  {/* Renderiza o textarea com a mensagem atual e o manipulador de mudança */}
                  {/*<textarea ref={textareaRef} value={message} onChange={handleTextChange} />*/}

                  <div className='flex items-center'>

                    {/* ---------------------------- BOTÃO ABRIR/FECHAR O SELETOR DE EMOTICONS ---------------------------- */}
                    <button onClick={toggleEmojiPicker}>
                      <div className='p-2 h-[50px]'>
                        <MdInsertEmoticon className="h-8 w-8 transition-all hover:h-9 hover:w-9" />
                      </div>
                    </button>


                    {/* ----------------------------------- SLIDER BUTTON ----------------------------------- */}
                    <label
                      className="ml-auto inline-flex items-center cursor-pointer"
                      title={'Cria um código Pix com o pagamento associado individualmente para cada cliente'}
                    >
                      <input
                        type="checkbox"
                        //value={toggleAddMsgPix}
                        className="sr-only peer"
                        onChange={handleAddMsgPix}
                        checked={toggleAddMsgPix}
                      />
                      <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                      <span className="ms-3 text-sm font-medium dark:text-gray-300">Adicionar mens. com código Pix</span>
                    </label>



                    {/* ----------------------------------- SELECT DO TIPO DE MENSAGEM ----------------------------------- */}
                    <select
                      onChange={handleSelectTipoMsgChange}
                      id="selectTipoMsg"
                      class="ml-auto mr-2 flex w-auto items-center justify-center rounded-xl border bg-white/0 p-1 text-sm outline-none"
                      ref={tipoMsgSelect}
                      value={mediaType} //{modalData.selectValue}
                    >
                      <option className="dark:bg-gray-700" value="text">Mens. De texto</option>
                      <option className="dark:bg-gray-700" value="image">Mens. De imagem</option>
                      <option className="dark:bg-gray-700" value="video">Mens. De vídeo</option>
                      {/* Adicione mais opções conforme necessário */}
                    </select>

                  </div>

                  {/* Se o seletor de emoticons estiver aberto, renderiza os emoticons */}
                  <div className='overflow-hidden'>
                    {isEmojiPickerOpen && (
                      <div id='conj-emoticons' className={`rounded-xl bg-[#ffffff17] p-2 w-auto h-auto aparecer-pra-baixo-default ${emojiPickerClass}`}>
                        {/* Para cada emoticon, renderiza um botão que, quando clicado, adiciona o emoticon à mensagem */}
                        {['😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '😊', '😇', '🙂', '🙃',
                          '😉', '😌', '😍', '🥰', '😘', '😋', '😛', '😝', '😜', '🤪', '🤨', '🧐', '🤓', '😎', '🤩',
                          '🥳', '😕', '🙁', '🥺', '😭'].map((emoji) => (
                            <button key={emoji} className='p-[2px] transition-all' onClick={() => handleEmojiClick(emoji)}>
                              {emoji}
                            </button>
                          ))}
                      </div>
                    )}
                  </div>
                </div>

                {/* -------------------------------------- INTERVALO DISPARO -------------------------------------- */}

                {/* TITULO DOS CAMPOS */}
                <div className='text-center mt-10 mb-4'>
                  <span
                    className=" text-sm font-bold text-navy-700 dark:text-white"
                  >
                    INTERVALO DE DISPARO
                  </span>
                </div>

                <div
                  className={`items-center inline-flex flex-wrap max-w-[170px] mx-auto transition-all duration-1000  md:mx-0 md:max-w-full`} //md:${recorrenciaTypeState == 'unicoEnvio' ? 'max-w-lg' : 'max-w-full'}
                //style={{maxWidth: '500px'}}
                >{/* grid gap-0 grid-cols-2 items-center _mx-auto max-w-[390px] md:mr-[175px] md:grid-cols-4 */}



                  {/* ------------------------------ SELECT "RECORRENTE/UNICO ENVIO" DE DISPARO ------------------------------ */}
                  <div className='w-min items-center mx-auto my-[10px] transition-all duration-1000'>
                    <select
                      onChange={handleSelectTipoRecorrencia}
                      id="select-Tipo-Recorrencia"
                      class="_mr-auto mt-8 h-12 rounded-xl border bg-white/0 p-1 text-sm outline-none"
                      ref={tipoRecorrenciaSelect}
                      value={recorrenciaTypeState}
                    >
                      <option className="dark:bg-gray-700" value="recorrente">Recorrente</option>
                      <option className="dark:bg-gray-700" value="unicoEnvio">Unico envio</option>
                    </select>
                  </div>




                  {/* ------------------------------ CAMPO P/ DEFINIR FREQUENCIA DO DISPARO ------------------------------ */}
                  <div
                    className={` text-center my-[10px] md:my-0
                    transition-opacity duration-500 ease-in-out 
                    ${recorrenciaTypeState === 'unicoEnvio' ? 'opacity-0 hidden' : 'opacity-100'}
                    `}
                  >
                    {/*<Fade
                      in={recorrenciaTypeState == 'unicoEnvio' ? false : true}
                      unmountOnExit={true}
                      transition={{
                        enter: { duration: 0.5 },
                        exit: { duration: 0.5 },
                        ease: "easeInOut",
                      }}
                      className={`text-center my-[10px] _transition-all _duration-1000 ${''/*recorrenciaTypeState == 'unicoEnvio' ? 'hidden' : ''* /}  md:my-0`}
                    > */} {/* grid gap-0 grid-cols-2 w-[130px] */}

                    <div className='items-center my-auto text-center'>
                      <span
                        className="mr-2 text-sm font-bold text-navy-700  dark:text-white"
                      >
                        A CADA
                      </span>
                    </div>

                    <InputField
                      placeholder="10..."
                      id=""
                      type="number"
                      extra="w-15 items-center mx-auto"
                      value={intervalValueState}
                      onChange={(e) => {
                        setModalData((prevState) => ({
                          ...prevState,
                          intervalValue: Number(e.target.value),
                        }));

                        setIntervalValueState(Number(e.target.value))
                      }}
                    />
                    {/*</Fade>*/}
                  </div>



                  {/* ------------------------------ SELECT DIA/MÊS/ANO DO DISPARO ------------------------------ */}
                  <div
                    className={` w-min items-center mx-auto
                    transition-opacity duration-500 ease-in-out 
                    ${recorrenciaTypeState === 'unicoEnvio' ? 'opacity-0 hidden' : 'opacity-100'}
                    `}
                  >
                    <select
                      onChange={handleSelectTipoIntervaloChange}
                      id="select-Tipo-Categoria"
                      class="_mr-auto mt-8 h-12 rounded-xl border bg-white/0 p-1 text-sm outline-none"
                      ref={tipoIntervaloSelect}
                      value={intervalTypeState}
                    >
                      <option className="dark:bg-gray-700" value="days">dias</option>
                      <option className="dark:bg-gray-700" value="months">meses</option>
                      <option className="dark:bg-gray-700" value="years">anos</option>
                    </select>
                  </div>


                  {/* ------------------------------ SELECT DIA DE DISPARO ------------------------------ */}
                  <div
                    className={` w-min items-center mx-auto
                    transition-opacity duration-500 ease-in-out 
                    ${recorrenciaTypeState === 'unicoEnvio' ? 'opacity-0 hidden' : (intervalTypeState == 'days' ? 'opacity-0 hidden' : 'opacity-100')}
                    `}
                  >
                    <div className='items-center my-auto text-center'>
                      <span
                        className="mr-2 text-sm font-bold text-navy-700  dark:text-white"
                      >
                        NO DIA
                      </span>
                    </div>
                    <select
                      onChange={handleSelectDiaDisparoChange}
                      id="select-Tipo-Categoria"
                      class="_mr-auto h-12 mt-2 w-[60px] rounded-xl border bg-white/0 p-1 text-sm outline-none"
                      ref={diaDisparoSelect}
                      value={diaDisparo}
                    >
                      {Array.from({ length: 31 }, (_, index) => {
                        const number = index + 1;
                        const formattedNumber = number.toString().padStart(2, '0');
                        return (
                          <option
                            key={number}
                            className="dark:bg-gray-700"
                            value={number}
                          >
                            {formattedNumber}
                          </option>
                        );
                      })}
                    </select>
                  </div>



                  {/* ----------------------------- CAMPO P/ DEFINIR HORARIO DO DISPARO ----------------------------- */}
                  <div className="text-center my-[10px]  md:my-0">{/* grid gap-0 grid-cols-2 w-max */}

                    <div className='items-center my-auto w-max'>
                      <label
                        htmlFor={''}
                        className="mr-2 text-sm font-bold text-navy-700 dark:text-white"
                      >
                        HORÁRIO DO DISPARO
                      </label>
                    </div>

                    <InputField
                      placeholder="12:00..."
                      id=""
                      type="time"
                      extra="w-full"
                      value={executionTimeState}
                      onChange={(e) => {
                        setModalData((prevState) => ({
                          ...prevState,
                          executionTime: e.target.value,
                        }));

                        setExecutionTimeState(e.target.value);
                      }}
                    />
                  </div>

                </div>


                {/* --------------------------- EXIBE LISTA DE FRASES/PALAVRAS GATILHOS --------------------------- */}
                <MessageTriggers />

                {/* ---------------------------------------- EXIBE BOTÕES ---------------------------------------- */}
                <div className="mt-10 flex justify-end gap-2">
                  <button
                    onClick={onClose}
                    className="linear rounded-xl border-2 border-red-500 px-5 py-3 text-base font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                  >
                    Cancelar
                  </button>
                  <button
                    onClick={() => editConfigMessages(editMsg)}
                    className="linear rounded-xl bg-brand-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                  >
                    Salvar
                  </button>
                </div>

              </Card>
            </ModalBody>
          </ModalContent>
        </Modal>

      </Card>}




      {/* ================================================== WIDGETS ================================================== */}

      {
        //configMessages.msgs?.map((dadosMsgAgendada, index) => {
        filteredWidgets?.map((dadosMsgAgendada, index) => {

          //console.log('dadosMsgAgendada: ', dadosMsgAgendada);

          return (
            <div>
              <Widget

                index={index} //{dadosMsgAgendada.id_msg}
                id_msg={dadosMsgAgendada._id}
                taskName={dadosMsgAgendada.taskName}
                //subtitle={dadosMsgAgendada.desc}
                intervalType={dadosMsgAgendada.intervalType}
                intervalValue={dadosMsgAgendada.intervalValue}
                frequency={dadosMsgAgendada.frequency}
                executionTime={dadosMsgAgendada.executionTime}
                categorySend={dadosMsgAgendada.categorySend}
                dataMsg={dadosMsgAgendada.dataMsg}
                diaDisparo={dadosMsgAgendada.diaDisparo}
                addMsgPix={dadosMsgAgendada.addMsgPix}

                status={dadosMsgAgendada.status}
                icon={<MdMessage className="h-6 w-6" />}
                value={configMessages}
                handleUpdateConfigMessages={handleUpdateConfigMessages}

                abrirModal={abrirModal}
                setMessage={setMessage}
                setMediaType={setMediaType}
                setLinkArq={setLinkArq}
                setEditMsg={setEditMsg}
                setTitulo={setTitulo}
                setCategorySendState={setCategorySendState}
                setIntervalValueState={setIntervalValueState}
                setIntervalTypeState={setIntervalTypeState}
                setExecutionTimeState={setExecutionTimeState}
                setDiaDisparo={setDiaDisparo}
                setToggleAddMsgPix={setToggleAddMsgPix}
              />
              {isOpen && <Modal data={modalData} onClose={() => {
                onOpen(false);
                setEditMsg(false);
              }} />}
            </div>

          );// Fim Return

        })// Fim Map filteredWidgets

      }


    </div >
  );
};

export default MessagesConfiguration;
