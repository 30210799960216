import { useState } from 'react';
import { InputGroup, InputRightElement, Input, Button } from '@chakra-ui/react';
import { MdVisibility, MdVisibilityOff } from "react-icons/md";



const PassInput = ({
    title,
    value,
    handleOnchange,
    placeholder
}) => {

    const [show, setShow] = useState(true);
    const handleClick = () => setShow(!show);



    return (

        <div className="mb-4">
            <label className="text-sm text-navy-700 font-bold dark:text-white">
                {title}
            </label>
            <InputGroup size='md'>
                <Input
                    type={show ? 'text' : 'password'}
                    placeholder={placeholder || ''}
                    value={value}
                    onChange={(e) => handleOnchange(e.target.value)}
                    className='w-full py-3 pl-5 pr-[60px] rounded-xl border bg-white/0 outline-none border-gray-200 dark:text-white'
                />
                <InputRightElement
                    width='4.5rem'
                    className='my-auto'
                    style={{ height: '-webkit-fill-available' }}
                >
                    <Button
                        h='1.75rem'
                        size='sm'
                        onClick={handleClick}
                        className='dark:text-white'
                    >
                        {show ? <MdVisibilityOff title='Ocultar' /> : <MdVisibility title='Exibir' />}
                    </Button>
                </InputRightElement>
            </InputGroup>
        </div>

    );

};

export default PassInput;