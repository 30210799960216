import { useDisclosure } from '@chakra-ui/hooks';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import Card from 'components/card';
import { useState, useRef, useEffect } from 'react';
import { MdEdit, MdInsertEmoticon, MdDelete } from 'react-icons/md';
import TextField from './TextField';
import api from 'api/api';
import Toast from 'components/toast';
import MessageTriggers from './MessageTriggers';

import InputField from '../components/InputField';

const Widget = ({
  icon,
  title,
  subtitle,
  msg,
  status,
  //type,
  value,
  handleUpdateConfigMessages,
  index,
  id_msg,
  diaDisparo,
  horarioDisparo,
  dadosMedia,
  abrirModal,
  addMsgPix,

  setMessage,
  setMediaType,
  setLinkArq,
  setEditMsg,
  setToggleAddMsgPix,
}) => {

  /*useEffect(() => {
    if (modalData) {
      abrirModal(modalData);
    }
  }, [modalData]);*/


  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalData, setModalData] = useState({
    id_msg: id_msg,
    title: title,
    desc: subtitle,
    msg: msg,
    diaDisparo: diaDisparo,
    horarioDisparo: horarioDisparo,
    status: status,
    dadosMedia: dadosMedia,
    selectValue: dadosMedia?.type || '',
    addMsgPix: addMsgPix,
  });
  //console.log('value.msgs: ', value.msgs);
  //console.log('index: ', index);

  const [isLoading, setIsLoading] = useState(false);

  // Define a referência para o textarea
  const textFieldRef = useRef(); // Referência para o TextField
  // Define o estado para controlar se o seletor de emoticons está aberto ou não
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  // Define o estado para armazenar a mensagem atual no textarea
  //const [message, setMessage] = useState(msg);
  // Define o estado para controlar a classe do seletor de emoticons
  const [emojiPickerClass, setEmojiPickerClass] = useState('hidden');

  // Define a função para alternar a visibilidade do seletor de emoticons
  const toggleEmojiPicker = () => {
    //console.log('Dados conj-emoticons: ', document.querySelector('#conj-emoticons').classList.contains('display') );
    //const conj_emoticons_display = document.querySelector('#conj-emoticons') ? document.querySelector('#conj-emoticons').classList.contains('display') : false;
    console.log('Display conj-emoticons: ', document.querySelector('#conj-emoticons')?.classList.contains('aparecer-pra-baixo'));
    if (document.querySelector('#conj-emoticons')?.classList.contains('aparecer-pra-baixo')) { //isEmojiPickerOpen
      setEmojiPickerClass('');
      /*setTimeout(() => {
        setIsEmojiPickerOpen(false);
      }, 1_000);*/ // Ajuste este valor para corresponder à duração da transição CSS
    } else {
      setIsEmojiPickerOpen(true);

      const element = document.querySelector('#conj-emoticons');
      element.style['margin-top'] = '-40%';
      element.style['display'] = '';
      //const marginCustom = Number('-' + $(seletor).css('height').replace('px', '')) - 20 + 'px';
      //element.style['margin-top'] = marginCustom;

      setEmojiPickerClass('aparecer-pra-baixo');
    }
  };

  // Define a função para lidar com o clique em um emoticon
  // Adiciona o emoticon clicado na posição do cursor no textarea e fecha o seletor de emoticons
  const handleEmojiClick = (emoji) => {
    if (textFieldRef.current) {
      const { selectionStart, value } = textFieldRef.current; //.querySelector('textarea'); //textareaRef.current;
      setMessage(value.slice(0, selectionStart) + emoji + value.slice(selectionStart));
      //setIsEmojiPickerOpen(false);
    }
  };

  // Define a função para lidar com a mudança no textarea
  // Atualiza a mensagem com o valor atual do textarea e mantém o foco no textarea
  const handleTextChange = (event) => {
    setMessage(event.target.value);
    //textareaRef.current.focus();
  };


  const toggleStatus = () => {

    value.msgs[index].status = !value.msgs[index].status;

    setIsLoading(true);

    let body = { ...value };
    //console.log('body: ', body);

    api
      .patch('MessageConfigs/' + value._id, body)
      .then((response) => {
        Toast.fire({
          icon: 'success',
          title: 'O status da mensagem foi alterado com sucesso!',
        });
        setTimeout(() => {
          console.log('response.data: ', response.data);
          handleUpdateConfigMessages(response.data);
        }, 1000);

        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });

  };


  const deleteMsg = () => {

    value.msgs.splice(index, 1);

    setIsLoading(true);

    let body = { ...value };
    console.log('body: ', body);

    api
      .patch('MessageConfigs/' + value._id, body)
      .then((response) => {
        Toast.fire({
          icon: 'success',
          title: 'A mensagem foi excluida com sucesso!',
        });
        onClose();
        //setTimeout(() => {
          console.log('response.data: ', response.data);
          handleUpdateConfigMessages(response.data);
        //}, 1000);

        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });

  };

  const openEditForm = () => {


    /*setModalData({
      id_msg: id_msg, //value.msgs[index].id_msg,
      title: title, //value.msgs[index].titulo, //types[type],
      desc: subtitle, //value.msgs[index].desc,
      msg: message, //msg, //value.msgs[index].msg, //value[type],
      diaDisparo: diaDisparo,
      horarioDisparo: horarioDisparo,
      dadosMedia: dadosMedia,
    });*/
    //setMessage(message); //value[type]    
    setMessage(msg);
    setLinkArq(dadosMedia?.media || '');
    //console.log('selectTipoMsg: ', document.querySelector('#selectTipoMsg'));
    //document.querySelector('#selectTipoMsg').value = dadosMedia?.media || '';
    setMediaType(dadosMedia?.type || '');

    setEditMsg(index);
    setToggleAddMsgPix(addMsgPix);

    //onOpen();
    abrirModal(modalData);
  };

  const editConfigMessages = () => {
    setIsLoading(true);

    let body = { ...value };
    if (modalData.msg !== '') body.msgs[index] = modalData;

    //console.log('value: ', value);
    console.log('body: ', body);

    api
      .patch('MessageConfigs/' + value._id, body)
      .then((response) => {
        Toast.fire({
          icon: 'success',
          title: 'Sua mensagem foi alterada com sucesso!',
        });
        onClose();
        setTimeout(() => {
          console.log('response.data: ', response.data);
          handleUpdateConfigMessages(response.data);
        }, 1000);

        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });
  };


  // Retorna o JSX para renderizar o componente
  return (

    <div
      /*className="animate-fadeIn"
      style={{
        animationDelay: `${index * 8.5}s`,
      }}*/
      style={{
        animation: 'fadeIn 1s',
        animationDelay: `${index * 0.2}s`,
        animationFillMode: 'backwards'
      }}
    >
      <Card extra="!flex-row _flex-grow _items-center flex-col pt-[10px] pb-[15px] rounded-[20px]  sm:flex-row md:py-0"> {/* !flex-row flex-grow items-center rounded-[20px]  //  grid grid-cols-1 gap-3 rounded-[20px]  md:grid-cols-3 */}


        {/* ====================================== LISTA NA PAGINA AS MSGS EXISTENTES ====================================== */}

        {/* ----------------- ICONE ----------------- */}
        <div className="ml-[18px] mr-3 flex h-[90px] w-auto flex-row items-center">
          <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
            <span className="flex items-center text-brand-500 dark:text-white">
              {icon}
            </span>
          </div>
        </div>


        <div className='flex  flex-col flex-grow items-center rounded-[20px]  sm:flex-row'>

          {/* ----------------- TITULO E DESCRIÇÃO ----------------- */}
          <div className="h-50 ml-4 flex w-full flex-col justify-center sm:justify-start sm:ml-0 sm:mt-4"> {/* h-50 ml-4 flex w-full flex-col justify-center */}
            {/* ----------------- titulo ----------------- */}
            <h4 className="text-xl font-bold text-navy-700 dark:text-white">
              {title}
            </h4>
            {/* ----------------- descrição ----------------- */}
            <p className="font-dm text-sm font-medium text-gray-600">
              {subtitle}
            </p>
          </div>


          {/* ----------------- BOTÕES AÇÕES ----------------- */}
          <div className="mt-4 flex w-full items-end space-x-5  sm:mt-0 sm:mr-5 md:justify-end"> {/* mr-5 flex w-full items-end justify-end space-x-5 */}
            {status !== true ? (
              <button
                onClick={() => toggleStatus()}
                className="rounded-lg bg-orange-500 px-3 py-2 text-xs font-bold uppercase text-white transition duration-200 dark:bg-orange-400">
                Desativado
              </button>
            ) : (
              <button
                onClick={() => toggleStatus()}
                className="rounded-lg bg-green-500 px-3 py-2 text-xs font-bold uppercase text-white transition duration-200 dark:bg-green-400">
                Ativado
              </button>
            )}

            <button
              onClick={() => openEditForm()}
              className="mr-auto flex justify-self-end rounded-xl bg-brand-500 p-1 text-2xl text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              title='Editar'
            >
              <MdEdit />
            </button>

            <button
              onClick={() => deleteMsg()}
              className="flex items-center justify-center rounded-xl bg-red-500 p-1 text-2xl text-white transition duration-200 hover:cursor-pointer hover:bg-red-700 active:bg-brand-700 dark:bg-red-500 dark:text-white dark:hover:bg-red-700 dark:active:bg-red-200 ampliar"
              title='Excluir cliente'
            >
              <MdDelete />
            </button>

          </div>

        </div>

      </Card>
    </div>
  );
};

export default Widget;
